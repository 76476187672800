import React, { useEffect, useState } from 'react'
import "../../css/kyc.css"
import ServiceCall from '../../ServiceCall'
import KycSubscription from './KycSubscription';
import KycDetails from './KycDetails';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DoneIcon from '@mui/icons-material/Done';
import {Tooltip} from "@mui/material"

function Kyc() {
  console.log("rendering")
  const [userList, setUserList] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [kycModalShow ,setKycModalShow] = useState(false)
  const [button, setButton] = useState(false)
  const [viewKycDetails, setKycDetails] = useState([])
  const [viewSubscription, setSubscription] = useState([]);
  // ******** It Define Header Click *************** 
  const [kycClick, setKycClick] = useState(0);
  const[kyc, setKyc] = useState([]);
  const [value, setValue] = useState()


  useEffect(()=>{
    handleUser()
},[])

useEffect(()=>{

},[kycModalShow])

// console.log(viewKycDetails)

const refresh = () =>{
  setUserList([])
  setKyc([])
  handleUser()
}

const handleUser = async () =>{
  const  result = await ServiceCall.get("rxone/admin/list/users",{
     'Authorization': `Bearer ${localStorage.getItem("rxone-admin")}`
   })
   if(result){
    setUserList(result.data.rxone_users)
    handleKyc(result.data.rxone_users)
   }
}
  const handleSubscription = async (item) =>{
    setSubscription([]);
    setModalShow(true)
    try{
      const result = await ServiceCall.get(`rxone/admin/user/subscriptions/status/${item.email}`,{
        'Authorization': `Bearer ${localStorage.getItem("rxone-admin")}`
      })
  
      if(result){
        setSubscription(result.data)
      }
    }catch(err){
      console.log(err)
      
    }

  }


  const handleKycDetails = async (item,index) =>{
    setKycModalShow(true)
    if(kyc[index]){
      console.log("kyc",kyc[index])
      setButton(true)
    }else{
      setButton(false)
    }
    try{
      const result = await ServiceCall.get(`rxone/admin/kyc/contact/details/${item.email}`,{
        'Authorization': `Bearer ${localStorage.getItem("rxone-admin")}`
      })

      if(result){
        setKycDetails(result.data)
      }

    }catch(err){
      setKycModalShow(false)
      console.log(err)
      
    }
  }

  

  const handleKyc =  async(user) =>{
    const userKyc =[];
    for(const item of user){
      const result =   await ServiceCall.get(`rxone/admin/user/kyc/status/${item.email}`,{
          'Authorization': `Bearer ${localStorage.getItem("rxone-admin")}`
        })
        userKyc.push(result.data.document_verified)
    }
    setKyc(userKyc)

  }
  
  console.log("button", button)
 
  return (

      
            <>
     {/* Subscription Modal Starts from herer setModalShow */}  
    
    
        <KycSubscription show={modalShow} subscriptiondata ={viewSubscription} onHide={() => setModalShow(false)} /> 

        <KycDetails show={kycModalShow} showbutton={button}  kycdetails ={viewKycDetails} refresh={refresh} onHide={() => setKycModalShow(false)} /> 
      {/* Subscription Modal End from herer setModalShow */}
    
    
    <div className='d-flex  kyc__main__container justify-content-center align-items-center ms--font-roboto'>
    <div className=' kyc__container shadow-sm mb-5 border rounded d-flex justify-content-center ' >
     <div className='kyc__table '>
       <div className='kyc__heading shadow-sm mb-4 row border rounded m-0 '>
      <h3 className={`col-6 text-center ${kycClick === 0 && "kyc__heading__click"}`} onClick={()=> setKycClick(0)}>USER</h3>
      <h3 className={`col-6 text-center ${kycClick === 1 && "kyc__heading__click"}`} onClick={()=> setKycClick(1)}>USER</h3>
      </div> 
       <div className='kyc__table__list mt-2 '>
       <div className="row kyc__main__table">
         <div className="col-3 fw-bold border btn__center">User Email</div>
         <div className="col-1 fw-bold border btn__center">User Since</div>
         <div className="col-1 fw-bold border btn__center">User Active?</div>
         <div className="col-2 fw-bold border btn__center">Last Login</div>
         <div className="col-2 fw-bold border btn__center">Subscription?</div>
         <div className="col-1 fw-bold border btn__center">KYC Status</div>
         <div className="col-2 fw-bold border btn__center">KYC Review</div>
       </div>
         { kyc.length===0?
            <div style={{height:"200px"}} className='btn__center'>
            <span className="spinner-border spinner-border-xl" role="status" aria-hidden="true"></span>
            </div>  :
         userList.map((list,index)=>(
       <div className="row kyc__table__items" key={list.email}>
         <div  className="col-3 border  btn__center text-break">{list.email}</div>
         <div className="col-1 border  btn__center ">{list.user_since.split(",").slice(0,1)}</div>
         <div className="col-1 border  btn__center">{list.activated ? "True" : "False" }</div>
         <div className="col-2 border  btn__center">{list.last_login.split(" ").slice(0,1)}</div>
         <div className="col-2 border  btn__center"><button style={{height:"30px"}} className='btn ms-secondary-btn fs-6 btn__center' onClick={()=>handleSubscription(list)}>open</button></div>
         <div className="col-1 border  btn__center">   
         <div style={{fontSize:"14px"}} className={`  btn__center fw-bolder  ${kyc[index] ? "text-success":"text-danger"}`} disabled>
           {kyc[index]?
             <Tooltip title="Completed" arrow>
               <DoneIcon  sx={{fontSize:"30px"}}/>
              </Tooltip>
              :
              <Tooltip title="Pending" arrow>
                <MoreHorizIcon sx={{fontSize:"30px"}}/>
              </Tooltip>
             }
           </div>
         </div>
         <div className="col-2 border  btn__center"><button style={{height:"30px"}} onClick={()=>handleKycDetails(list,index)} className='btn ms-secondary-btn fs-6 d-flex justify-content-center align-items-center'>open</button></div>
       </div>
         ))
         }
       </div>
     </div>
    </div>
    </div>
    </>
  )
}

export default Kyc