import Reactd,{useState} from 'react'
import {Link,NavLink} from "react-router-dom"
import "../css/header.css"
import HomeIcon from '@mui/icons-material/Home';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import logo from "../images/footer logo.svg"
import AssignmentIndRoundedIcon from '@mui/icons-material/AssignmentIndRounded';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

function Header() {

   const[navigation, setNavigation] = useState(0)

  return (
    <div className='main__header'>
    <div className="main__logo rounded">
      <img src={logo} alt="header_logo" /> 
    </div>
     <hr style={{width:"80%"}} className='bg-light pt-1 m-auto mt-3'/>
     <div className="header__bar">
       <ul>
         <li className={ `${navigation == 0 && "nav__background"}`} ><NavLink  onClick={()=>setNavigation(0)} activeclassname = "active " to="/"><HomeIcon className='mb-2 me-2'/>Home</NavLink></li>
         <li className={ `${navigation == 1 && "nav__background"}`}><NavLink onClick={()=>setNavigation(1)} style={{padding:"8px 20px"}} activeclassname = "active" to="/home/kyc"><LocationCityIcon className='mb-1 me-2'/>KYC</NavLink></li>
         <li className={ `${navigation == 2 && "nav__background"}`}><NavLink onClick={()=>setNavigation(2)} style={{padding:"8px 20px"}} activeclassname = "active" to="/home/facilites"><DomainVerificationIcon className='mb-1 me-2'/>Facilites</NavLink></li>
         <li className={ `${navigation == 3 && "nav__background"}`}><NavLink onClick={()=>setNavigation(3)} style={{padding:"8px 20px"}} activeclassname = "active" to="/home/lookups"><TravelExploreIcon className="mb-1 me-2"/>Lookups</NavLink></li>
         <li className={ `${navigation == 4 && "nav__background"}`}><NavLink onClick={()=>setNavigation(4)} style={{padding:"8px 20px"}} activeclassname = "active" to="/home/onboard"><AssignmentIndRoundedIcon className="mb-1 me-2"/>On Board</NavLink></li>
         <li className={ `${navigation == 5 && "nav__background"}`}><NavLink onClick={()=>setNavigation(5)} style={{padding:"8px 20px"}} activeclassname = "active" to="/home/rewards"><EmojiEventsIcon className="mb-1 me-2"/>Rewards</NavLink></li>
         <li className={ `${navigation == 6 && "nav__background"}`}><NavLink onClick={()=>setNavigation(6)} style={{padding:"8px 20px"}} activeclassname = "active" to="/home/requests"><ReceiptLongIcon className="mb-1 me-2"/>Requests</NavLink></li>
         <li className={ `${navigation == 7 && "nav__background"}`}><NavLink onClick={()=>setNavigation(7)} style={{padding:"8px 20px"}} activeclassname = "active" to="/home/settelement"><PeopleIcon className="mb-1 me-2"/>Settlements</NavLink></li>
         <li className={ `${navigation == 8 && "nav__background"}`}><NavLink onClick={()=>setNavigation(8)} style={{padding:"8px 20px"}} activeclassname = "active" to="/home/incident"><AssignmentLateIcon className='mb-1 me-2'/>Incident</NavLink></li>
         <li className={ `${navigation == 9 && "nav__background"}`}><NavLink onClick={()=>setNavigation(9)} style={{padding:"8px 20px"}} activeclassname = "active" to="/home/setting"><SettingsIcon className="mb-1 me-2"/>Setting</NavLink></li>
       </ul>
     </div>
    </div>
  )
}

export default Header