import fileDownload from 'js-file-download';
import React,{useEffect, useState} from 'react'
import {Modal, Button} from "react-bootstrap"
import ServiceCall from '../../ServiceCall';
import Snackbar from '../../Theme/Snackbar';


function KycDetails(props) {
  const [currStep, setCurrStep] = useState(0);
  const [viewBusinessDetails, setBusinessDetails] = useState("");
  const [viewAccountDetails, setAccountDetails] = useState("");
  const [viewDocumentDetails, setDocumentDetails] = useState("");
  const [checkBox, setCheckBox] = useState(false);
  const [isDisabled, setDisabled] = useState(false)
  const [button, setButton] = useState([false, false, false, false])
  const [loader, setLoader] = useState(false)
  const [razorpay,setRazorpay] = useState("")
  const [snackbar, setSnackbar] = useState("")
  const [refresh, setRefresh] = useState(false)

useEffect(()=>{
  if(!props.show){
    setBusinessDetails("")
    setCurrStep(0)
    setAccountDetails("")
    setDocumentDetails("")
    setButton([false, false,false,false])
    setLoader(false)
    setRazorpay("")
    if(refresh){
      props.refresh()
    }
  }
},[props.show])

useEffect(()=>{
  if(currStep === 1){
    handleBusinessDetails()
  }
  if(currStep === 2){
    handleAccountDetails()
  }
  if(currStep === 3){
    handleDocumentVerification()
  }

},[currStep])

const handleBusinessDetails = async() =>{
  setBusinessDetails("")
  setCurrStep(1)
  const  result = await ServiceCall.get(`rxone/admin/kyc/business/details/${props.kycdetails["Contact Email"]}`,{
    'Authorization': `Bearer ${localStorage.getItem("rxone-admin")}`
  })
  if(result){
    setBusinessDetails(result.data)
  }
}

const handleAccountDetails = async() =>{
  setAccountDetails("")
  setCurrStep(2)
  const  result = await ServiceCall.get(`rxone/admin/kyc/account/details/${props.kycdetails["Contact Email"]}`,{
    'Authorization': `Bearer ${localStorage.getItem("rxone-admin")}`
  })
  if(result){
    setAccountDetails(result.data)
  }
}

const handleDocumentVerification = async() =>{
  setDocumentDetails("")
  setCurrStep(3)
  const  result = await ServiceCall.get(`rxone/admin/kyc/documents/${props.kycdetails["Contact Email"]}`,{
    'Authorization': `Bearer ${localStorage.getItem("rxone-admin")}`
  })
  if(result){
    setDocumentDetails(result.data)
  }
}


const handleDownloadDocument = async(fileName) =>{
  const  result = await ServiceCall.get(`rxone/admin/get/kyc/document/${props.kycdetails["Contact Email"]}/${fileName}`,{
    'Authorization': `Bearer ${localStorage.getItem("rxone-admin")}`
  }).then((res)=> {
    console.log(res)
    console.log(fileName)
    fileDownload(res.data, fileName)
  })
}


const handleSubmit =async (e) =>{
  e.preventDefault()
  if(e.target.id === "contact"){
    if(!checkBox){
      alert("Please Fill the Checkbox")
    }else{
      setLoader(true)
      const  result = await ServiceCall.put(`rxone/admin/kyc/contact/details/${props.kycdetails["Contact Email"]}`,"",{
       'Authorization': `Bearer ${localStorage.getItem("rxone-admin")}`  
     })
     if(result){
       button[currStep] = true;
           setLoader(false)
       setCurrStep(e=>e+1)
     }
    }
  }else if(e.target.id === "business"){
    if(!checkBox){
      alert("Please Fill the Checkbox")
    }else{
      setLoader(true)
      const  result = await ServiceCall.put(`rxone/admin/kyc/business/details/${props.kycdetails["Contact Email"]}`,"",{
       'Authorization': `Bearer ${localStorage.getItem("rxone-admin")}`  
     })
     if(result){
      button[currStep] = true;
          setLoader(false)
      setCurrStep(e=>e+1)
    }
    }
  }else if(e.target.id === "account"){
    if(!checkBox){
      alert("Please Fill the Checkbox")
    }else{
      setLoader(true)
      const  result = await ServiceCall.put(`rxone/admin/kyc/account/details/${props.kycdetails["Contact Email"]}`,{
        "rz_account_id":razorpay
      },{
       'Authorization': `Bearer ${localStorage.getItem("rxone-admin")}`  
     })
     if(result){
      button[currStep] = true;
          setLoader(false)
      setCurrStep(e=>e+1)
    }
    }

  }else if(e.target.id === "document"){
    if(!checkBox){
      alert("Please Fill the Checkbox")
    }else{
      let result = null
      try{
      setLoader(true)
        result = await ServiceCall.put(`rxone/admin/kyc/documents/${props.kycdetails["Contact Email"]}`,"",{
       'Authorization': `Bearer ${localStorage.getItem("rxone-admin")}`  
     })
     console.log("result",result)
     if(result){
      button[currStep] = true;
      setLoader(false)
     setRefresh(true)
     setSnackbar(result?.data?.message || "")
    }
  }catch(err){
    setLoader(false)
    console.log("result", err.response)
    setSnackbar(err.response.data.message)
  }
    }

  }
  return ()=>{
    setCheckBox(false)

  }

}

const handleRazorpay =(item) =>{
 if(item.rz_account_id === ""){
   return razorpay;
 }
   return item.rz_account_id;
}

const handleRazorpayValue =(item) =>{
  const value = item.target.value;
  setRazorpay(value)
  setDisabled(false)

}
    
  return (
    <>
    <Snackbar msg={snackbar} setmsg={setSnackbar} refresh={refresh ? props.onHide : false}/>
    <Modal
    {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  > 
    <Modal.Header className='p-2 ps-4 pe-4 ' closeButton>
    <h5 className='ms--font-roboto  ms-primary-color p-0 fw-bolder ' >Kyc Details</h5>
    </Modal.Header>
    <Modal.Body style={{padding:"0px 12px"}}>
      <div className="row ">
     <div style={{padding : "0px", height:"inherit", backgroundColor:"#F3F3F2"}} className="col-3 ">
       <ul className='list-group kyc__details__nav mt-4'>
         <li className={`list-group ${currStep === 0 && "ms-second-bg-color"} `} onClick={()=>setCurrStep(0)}>Contect Info</li>
         <li className={`list-group ${currStep === 1 && "ms-second-bg-color"} `} onClick={()=>setCurrStep(1)}>Business Details</li>
         <li   className={`list-group ${currStep === 2 && "ms-second-bg-color"} `} onClick={()=>setCurrStep(2)}>Acount Details</li>
         <li className={`list-group ${currStep === 3 && "ms-second-bg-color"} `} onClick={()=>setCurrStep(3)}> Document Verification</li>
         {/* <li>Settlements</li> */}
       </ul>
     </div>
     <div className='col-9 ms--font-roboto mt-5 mb-5 ' >
       <div style={{gap:"15px"}} className='row fs-6  '>

         {/* curr step one starts from here  */}

         {
           currStep === 0 && 
           <>
            <div className='row'>
            <label className='col text-center'>Contact Name</label>
            <input disabled value = {props.kycdetails["Contact Name"]} className=' col text-center fs-6'/>
            </div>
            <div className='row'>
            <label className=' col text-center' >Contact Number</label>
            <input disabled value = {props.kycdetails["Contact Number"]} className=' col text-center'/>
            </div>
            <div className='row'>
            <label className=' col text-center'>Contact Email</label>
            <input disabled value = {props.kycdetails["Contact Email"]} className=' col text-center'/>  
            </div>

            {!props.showbutton && 

             <form id="contact" onSubmit={handleSubmit}>
            <div className='d-flex justify-content-center  mt-5 me-5'>   
              <div className='me-5'>
                <label className='fs-6'>Verified :</label>
                <input type="checkbox" onChange={(e)=>setCheckBox(e.target.checked)} className='ms-2'/>
              </div>
              <button className={`btn ms-secondary-btn kyc__details__button ${button[currStep] && "disabled"}`} aria-disabled="true">  Save & Next {loader &&  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}</button>
              </div>
             </form>
            }

           </>
         }

         { currStep ===1 ?
             viewBusinessDetails.length == 0 ?
              <div className="d-flex justify-content-center"> 
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
            :
            <>
            <div className='row'>
            <label className='col text-center'>Business Name</label>
            <input disabled value = {viewBusinessDetails.business_name} className=' col'/>
            </div>
            <div className='row'>
            <label className=' col text-center'>Business PAN No.</label>
            <input disabled  value = {viewBusinessDetails.business_pan_number} className=' col'/>
            </div>
            <div className='row'>
            <label className=' col text-center'>CIN</label>
            <input disabled value = {viewBusinessDetails.business_cin} className=' col'/>
            </div>
            <div className='row'>
            <label className=' col text-center'>Business Type</label>
            <input disabled value = {viewBusinessDetails.business_type} className=' col'/>
            </div>
            <div className='row'>
            <label className=' col text-center'>Authorized Signatory</label>
            <input disabled value = {viewBusinessDetails.authorized_signatory} className=' col'/>
            </div>
            <div className='row'>
            <label className=' col text-center'>Signatory PAN No.</label>
            <input disabled value = {viewBusinessDetails.authorized_signatory_pan_number} className=' col'/>
            </div>
            <div className='row'>
            <label className=' col text-center'>Business Address</label>
            <input disabled value = {viewBusinessDetails.business_address} className=' col'/>
            </div>
            <div className='row'>
            <label className=' col text-center'>Pin Code</label>
            <input disabled value = {viewBusinessDetails.pin_code} className=' col'/>
            </div>

            {!props.showbutton && 
            <form id="business" onSubmit={handleSubmit}>
            <div className='d-flex justify-content-center  mt-5 me-5'>
              <div className='me-5'>
                <label className='fs-6'>Verified :</label>
                <input type="checkbox" onChange={(e)=>setCheckBox(e.target.checked)} className='ms-2'/>
              </div>
              <button className={`btn ms-secondary-btn kyc__details__button ${button[currStep]&&"disabled"}`} aria-disabled="true">  Save & Next {loader &&  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}</button>
              </div>
            </form>
            }
           </>
         :
         <></>
         }

         
        { currStep ===2 ?

         viewAccountDetails.length === 0 ?
              <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
        :
            <>
            <div className='row'>
            <label className='col text-center'>Beneficiary Name</label>
            <input disabled value = {viewAccountDetails.beneficiary_name} className=' col'/>
            </div>
            <div className='row'>
            <label className=' col text-center'>Branch IFSC Code</label>
            <input disabled value = {viewAccountDetails.branch_ifsc_code} className=' col'/>
            </div>
            <div className='row'>
            <label className=' col text-center'>Account Number</label>
            <input disabled value = {viewAccountDetails.account_number} className=' col'/>
            </div>
            <div className='row'>
            <label className=' col text-center fw-bold'>Razor Pay Account No.</label>
            <input id="razor" value={handleRazorpay(viewAccountDetails)} required className={`col ${isDisabled && "disabled"}`} aria-disabled="true" onChange={(e)=>{handleRazorpayValue(e)}} />
            </div>

            {!props.showbutton && 
            <form id="account" onSubmit={handleSubmit}>
            <div className='d-flex justify-content-center  mt-5 me-5'>
              <div className='me-5'>
                <label className='fs-6'>Verified :</label>
                <input type="checkbox" className='ms-2' onChange={(e)=>setCheckBox(e.target.checked)} />
              </div>
              <button className={`btn ms-secondary-btn kyc__details__button e${button[currStep]&&"disabled"}`} aria-disabled="true"> Save & Next {loader &&  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>} </button>
              </div>
              </form>
              }     
           </>
         :
          <></>
         }

         {
           currStep === 3 ?
           viewDocumentDetails.length === 0? 
           <div className="d-flex justify-content-center">
           <div className="spinner-border" role="status">
             <span className="visually-hidden">Loading...</span>
           </div>
         </div>
         : 
         Object.keys(viewDocumentDetails)[0] === "message" ? 
          <h4 className='fs-6 text-center'>User has not yet uploaded KYC documents.</h4> 
          :
         
         <div>
        <div className="row ms-second-bg-color p-0">
           <div className="col-5 fw-bold border text-center">File Name</div>
           <div className="col-2 fw-bold border text-center">File Type</div>
           <div className="col-2 fw-bold border text-center">Uploaded on</div>
           <div className="col-3 fw-bold border text-center">Download</div>
         </div>
         {
           viewDocumentDetails.kyc_documents.map(list =>(
            <div style={{fontSize:"14px"}} className="row  p-0" key={list.file_name}>
            <div style={{overflow:"auto"}} className="col-5 fw-bold border d-flex justify-content-start text-break align-items-center ">{list.file_name}</div>
            <div className="col-2 fw-bold border text-center">{list.file_type}</div>
            <div className="col-2 fw-bold border text-center">{list.uploaded_on.split(" ").slice(0,1)}</div>
            <div className="col-3 fw-bold border d-flex justify-content-center align-items-center"><button className='btn ms-secondary-btn kyc__details__button ' onClick={()=>handleDownloadDocument(list.file_name)}> Download </button></div>
          </div>
           ))
         }
         


         {!props.showbutton && 
           <form id="document" onSubmit={handleSubmit}>
            <div className='d-flex justify-content-end  mt-5 me-5'>

            <div className='me-5'>
              <label className='fs-6'>Verified :</label>
              <input type="checkbox" className='ms-2' onChange={(e)=>setCheckBox(e.target.checked)} />
            </div>
            <button className={`btn ms-secondary-btn kyc__details__button ${button[currStep]&& "disabled"}`} aria-disabled="true"> Save & Next {loader &&  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>} </button>
            </div>
            </form>
          }
         </div>
         :
         <></>
         }

       </div>
     </div>

      </div>
    </Modal.Body>
  </Modal>
  </>
  )
}

export default KycDetails