import React, { useState } from 'react'
import { useEffect } from 'react'
import ServiceCall from '../../../ServiceCall'
import { Modal } from 'react-bootstrap'
import { apiCall } from '../../../ServiceCall'
import { useFormik } from 'formik'
import RewardLastModal from './RewardLastModal'
import PrescriptionEditor from '../../PrescriptionEditor'
import { PropaneSharp, Refresh } from '@mui/icons-material'
import Snackbar from '../../../Theme/Snackbar'


const RewardModal = (props) => {
  const [currStep, setCurrStep] = useState(0)
  const [isImage, setImage] = useState('')
  const [cuponImage, setCuponImage] = useState('')
  const [loader, setLoader] = useState(false)
  const [disableView, setDisableView] = useState(true);
  const [errorMsg, setErrorMsg] = useState("")
  const [pData, setPData] = useState({
    symptoms_list: [],
    findings_list: [],
    tests_list: [],
    drugs_list: []
  });

  const pdataObj = {
    symptoms_list: 'symptoms',
    findings_list: 'diseases',
    tests_list: 'diagnosis',
    drugs_list: 'therapies'
  };

  const classes = {
    listItem: 'makeStyles-listItem-13', 
    listHover: 'makeStyles-listHover-14', 
    label: 'makeStyles-label-15',
    radioTime: 'makeStyles-radioTime-16', 
    timeButton: 'makeStyles-timeButton-17',
    apiProgress: "makeStyles-apiProgress-23",
    blackBtn: "makeStyles-blackBtn-24",
    danger: "makeStyles-danger-22",
    headerBg: "makeStyles-headerBg-19",
    input: "makeStyles-input-20",
    label: "makeStyles-label-15",
    listHover: "makeStyles-listHover-14",
    listItem: "makeStyles-listItem-13",
    radio: "makeStyles-radio-18",
    radioTime: "makeStyles-radioTime-16",
    stackStyle: "makeStyles-stackStyle-21",
    timeButton: "makeStyles-timeButton-17"
  }


  useEffect(() => {
    setImage('')
    if (currStep === 2 || currStep === 1) {
      setCuponImage('')
      image()
    }
  }, [currStep])

  useEffect(()=>{
    if(!props.show){
       props.refresh()
       setCurrStep(0)
       setLoader(false)
    }
  },[props.show])
  

  useEffect(()=>{
    return()=>{

    props.setrewardsid("")
        setCurrStep(0)
        
    }
  },[])

  console.log("rewardsid",props.rewardsid)

const value = {
  title:"",
  required_claim_points:"",
  allowed_quantity:"",
  category:"",
  valid_till:"",
  redemption_steps:"",
  terms_conditions:""
}

  const [userDetails, setUserDetails] = useState(value)
  const [open, setOpen] = useState(false)
  const [lastModal ,setLastModal] = useState(false)
  const [msg, setMsg] = useState("")
  const [redemlist, setRedemlist]= useState("")
  const [termslist, setTermslist] = useState("")

  const [receiveNotes, setReceiveNotes] = useState({
    symptoms_list: [],
    findings_list: [],
    tests_list: [],
    drugs_list: []
  });

  const image = async () => {
    let url = `rxone/admin/user/get/reward/image/${props.rewardsid}`
    let userToken = `Bearer ${localStorage.getItem('rxone-admin')}`
  try{
    const imageUrl = await apiCall({
      url,
      method: 'get',
      responseType: 'blob',
      headers: {
        pt_token: userToken,
      },
    }).then((res) => {
      var imageUrl = window.URL.createObjectURL(res.data)
      setCuponImage(imageUrl)
      setOpen(true)
    })

  }catch(err){
    setErrorMsg(err.response.data.message || "")
  }
  }


  const onImageChange = (e) => {
    const imageUrl = e.target.files
    setImage(imageUrl)
  }

  const handleReward = async (state) =>{
    try {
      const result = await ServiceCall.put(
        `rxone/admin/user/rewards`,
          {
            "title":userDetails.title,
            "required_claim_points":userDetails.required_claim_points,
            "allowed_quantity":userDetails.allowed_quantity,
            "category":userDetails.category,
            "valid_till":userDetails.valid_till,
            "redemption_steps_list":userDetails.redemption_steps,
            "terms_conditions_list":userDetails.terms_conditions,
          }
          ,{
          Authorization: `Bearer ${localStorage.getItem('rxone-admin')}`,
        },
      )
      if (result) {
        props.setrewardsid(result.data.reward_id)
       state && setCurrStep(1)
        setLoader(false)
      }
    } catch (err) {
      setErrorMsg(err.response.data.message || "")
    }
  }

  const handlepublish = async () => {
    try {
      const result = await ServiceCall.put(
        `rxone/admin/user/reward/publish/switch/${props.rewardsid}`,"",{
          Authorization: `Bearer ${localStorage.getItem('rxone-admin')}`,
        },
      )
      if (result) {
        console.log("publish", result.data)
        setLoader(false)
        setLastModal(true)
      }
    } catch (err) {
      setErrorMsg(err.response.data.message || "")
      setLoader(false)
    }
  }


  const handleSubmit = async (e) => {
    e.preventDefault()
    const target = e.target.id
    setLoader(true)
    console.log("target", target)
    if (target === "reward-details" || target === "reward-details-next") {
      const userDetailsValues = Object.values(userDetails)
      const userDetailsKey = Object.keys(userDetails)
      let valid = false
      for(let i =0; i<userDetailsValues.length; i++){
         if(userDetailsValues[i] === "" || userDetailsValues[i].length=== 0){
           document.getElementById(userDetailsKey[i]).style.border = "1px solid red"
           valid = false;
         }else{
          valid = true;
         }
      }
      if(valid){
        if(target === "reward-details"){
          handleReward(false)
        }else{
          handleReward(true)
        }
      }else{
        setLoader(false)
      }
    }else if (target === "upload-image" || target === "upload-image-next") {

      var myHeaders = new Headers();
      myHeaders.append('Content-Type', 'multipart/form-data');
      myHeaders.append('pt_token',`Bearer ${localStorage.getItem('rxone-admin')}`);
      
      var formdata = new FormData()
      formdata.append('reward_image', isImage[0], isImage[0].name); // appending every file to formdata
      console.log("image",formdata)


      try {
        const result = await ServiceCall.put(
          `rxone/admin/user/reward/image/${props.rewardsid}`,
          formdata
            ,{
            Authorization: `Bearer ${localStorage.getItem('rxone-admin')}`,
            'Content-Type': 'multipart/form-data'
          },
        )
        if (result) {
          console.log("resultData", result.data)
          setCurrStep(2)
          setLoader(false)
        }
      } catch (err) {
        setErrorMsg(err.response.data.message || "")
        setLoader(false)
      }
    }else if(target === "publish-cancel" || target === "publish-next"){
      if(target === "publish-cancel"){
        props.onHide();
      }else{
        handlepublish()
      }
    }
  }

  function checkDisable() {
    Object.keys(pData).find((key) => {
      if (pData[key].length >= 0) {
        setDisableView(false);
      }
    });
  }

  function updatePData(value, type) {
    let addedValue = { ...pData, [type]: value };
    setPData(addedValue);
    checkDisable();
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="p-2 ps-4 pe-4 " closeButton>
        <h5 className="ms--font-roboto  ms-primary-color p-0 fw-bolder ">
          {props.title}
        </h5>
      </Modal.Header>
      <Modal.Body style={{ padding: '0px 12px' }}>
        <div className="row ">
      <Snackbar msg={errorMsg} setmsg={setErrorMsg} refresh={props.onHide}/>
        <RewardLastModal rewardsid={props.rewardsid} setopen={setLastModal} open={lastModal}/> 
          <div
            style={{
              padding: '0px',
              height: 'inherit',
              backgroundColor: '#F3F3F2',
            }}
            className="col-3 "
            >
              <p
                style={{ fontSize: '15px' }}
                className=" text-center mb-3 mt-4 ms--font-roboto"
              >
                {props.lineheading}
              </p>

            <ul className="list-group kyc__details__nav mt-2">
              <li
                className={`list-group ${
                  currStep === 0 && 'ms-second-bg-color'
                } `}
                onClick={() => props.navBar && setCurrStep(0)}
              >
                Reward Details
              </li>
              <li
                className={`list-group ${
                  currStep === 1 && 'ms-second-bg-color'
                } `}
                onClick={() =>props.navBar && setCurrStep(1)}
              >
                Upload Image & Save
              </li>
              <li
                className={`list-group ${
                  currStep === 2 && 'ms-second-bg-color'
                } `}
                onClick={() => props.navBar && setCurrStep(2)}
              >
                Publish
              </li>
              {/* <li>Settlements</li> */}
            </ul>
          </div>
          <div className="col-9 ms--font-roboto mt-5 mb-5 ">
            <div style={{ gap: '6px' }} className="row fs-6  ">
              {props?.data?.length === 0 || false ? (
                <div style={{ height: '200px' }} className="btn__center">
                  <span
                    className="spinner-border spinner-border-xl"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </div>
              ) : (
                <>
                  {currStep === 0 && (
                    <form id="business" className='reward_business'>
                      <div className="row pe-2">
                        <label className="col-3 text-center">Title</label>
                        <input
                          type="text"
                          aria-disabled="true"
                          value={
                            props?.data?.title 
                          }
                          id="title"
                          onChange={(e)=>
                            setUserDetails(prv=>({
                              ...prv,
                              title: e.target.value
                            }))
                          }
                          className="col "
                        />
                      </div>
                      <div className="row pe-2 ">
                        <label className=" col-3 text-center">Valid Till</label>
                        <input
                       type="date"
                       id="valid_till"
                          value={
                            props?.data?.valid_till 
                          }
                          onChange={(e)=>
                            setUserDetails(prv=>({
                              ...prv,
                              valid_till: e.target.value
                            }))
                          }
                          className=" col-3"
                        />
                        <label style={{ fontSize: '14px' }} className=" col-2 ">
                        </label>
                        <select className="col-4 pe-2"
                         id="category"
                         onChange={(e)=>
                          setUserDetails(prv=>({
                            ...prv,
                            category: e.target.value
                          }))
                        } >
                          <option value="">{props?.data?.category || "Select Category"}</option>
                          <option value="Electronics">Electronics</option>
                          <option value="Food & Entertainment">Food & Entertainment</option>
                          <option value="Fashion Accessories">Fashion Accessories</option>
                          <option value="Grooming">Grooming</option>
                          <option value="Apparel">Apparel</option>
                          <option value="Games & Stationary">Games & Stationary</option>
                          <option value="Appliances">Appliances</option>
                        </select>
                      </div>
                      <div className="row pe-2">
                        <label className=" col-3 text-center">
                          Input Points to Claim
                        </label>
                        <input
                         id="required_claim_points"
                          value={
                            props?.data?.required_claim_points 
                          }
                          onChange={(e)=>
                            setUserDetails(prv=>({
                              ...prv,
                              required_claim_points: e.target.value
                            }))
                          }
                          className=" col"
                        />
                      </div>
                      <div className="row pe-2">
                        <label className=" col-3 text-center">
                          Input Rewards Quantity
                        </label>
                        <input
                         id="allowed_quantity"
                          value={
                            props?.data?.allowed_quantity 
                          }
                          onChange={(e)=>
                            setUserDetails(prv=>({
                              ...prv,
                              allowed_quantity: e.target.value
                            }))
                          }
                          className=" col"
                        />
                      </div>
                      <div className="row pe-2">
                        <label className=" col-3 text-center">
                          How to Redeem
                        </label>
                        {/* <input  disabled  className=' col-9' /> */}
                        {/* <input
                          disabled={props?.data ? 'disabled' : ''}
                          className=" col-9 mb-2"
                        />
                        <div className="col-3"></div>
                        <div className="col-9 border pt-1 pb-1 bg-light">
                          <ul>
                            {props?.data?.redemption_steps.map((e) => (
                              <li key={e}>{e}</li>
                            ))}
                          </ul>
                        </div> */}
                        <div className='col-9 '>
                          <PrescriptionEditor
                            id="redemption_steps"
                            fetchType="redemption_steps"
                            editable={true}
                            placeholder={'Input Redeem Conditions'}
                            changeHandler={updatePData}
                            setdata={setUserDetails}
                            data={props.data}
                            asyncData={false}
                            classes={classes}
                            pdataObj={pdataObj}
                            receiveNotes={receiveNotes['symptoms_list'] || []}
                          />
                        </div>

                      </div>
                      <div className="row pe-2">
                        <label className=" col-3 text-center">
                          Terms and Conditions
                        </label>
                          <div className='col-9'>
                          <PrescriptionEditor
                            fetchType="terms_conditions"
                            id="terms_conditions"
                            editable={true}
                            setdata={setUserDetails}
                            placeholder={'Input Terms and Conditions'}
                            changeHandler={updatePData}
                            asyncData={false}
                            data={props.data}
                            classes={classes}
                            pdataObj={pdataObj}
                            receiveNotes={receiveNotes['symptoms_list'] || []}
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-center  mt-5 me-5">
                        <div className="me-5">
                          <button
                            id = "reward-details"
                            className={`btn ms-secondary-btn kyc__details__button `}
                            aria-disabled="true"
                            onClick={handleSubmit}
                          >
                            {' '}
                            Save{' '}
                            {loader && (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            )}
                          </button>
                        </div>
                        <button
                          id = "reward-details-next"
                          className={`btn ms-secondary-btn kyc__details__button `}
                          aria-disabled="true"
                          onClick={handleSubmit}
                        >
                          {' '}
                          Save & Next{' '}
                          {loader && (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                        </button>
                      </div>
                    </form>
                  )}

                  {currStep === 1 && (
                    <div>
                      <div className="d-flex justify-content-around align-items-center">
                        <div className="align-items-center reward_image_box border">
                          <img src={isImage[0]? URL.createObjectURL(isImage[0]):cuponImage} alt="manager-reward" />
                        </div>
                        <div className="align-items-center">
                          <input
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={onImageChange}
                          />
                        </div>
                      </div>
                        <div className="d-flex justify-content-center  mt-5 me-5">
                          <div className="me-5">
                            <button id="upload-image"
                             type='submit'
                              className={`btn ms-secondary-btn kyc__details__button `}
                              aria-disabled="true"
                              onClick={handleSubmit}
                            >
                              {' '}
                              Save{' '}
                              {loader && (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}
                            </button>
                          </div>
                          <button
                            id="upload-image-next"
                            className={`btn ms-secondary-btn kyc__details__button `}
                            aria-disabled="true"
                            onClick={handleSubmit}
                          >
                            {' '}
                            Save & Next{' '}
                            {loader && (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            )}
                          </button>
                        </div>
                    </div>
                  )}

                  {currStep === 2 && (
                    <div>
                      <div className="btn__center">
                        <div className="reward_cupon_image">
                          <img src={cuponImage} alt="cupon-image" />
                        </div>
                      </div>
                      <form id="business" >
                        <div className="d-flex justify-content-center  mt-5 me-5">
                          <div className="me-5">
                            <button
                              id = "publish-cancel"
                              className={`btn ms-secondary-btn kyc__details__button `}
                              aria-disabled="true"
                              onClick={handleSubmit}
                            >
                              {' '}
                              Cancel{' '}
                              {loader && (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}
                            </button>
                          </div>
                          <button
                            id ="publish-next"
                            className={`btn ms-secondary-btn kyc__details__button `}
                            aria-disabled="true"
                            onClick={handleSubmit}
                          >
                            {' '}
                            Publish{' '}
                            {loader && (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            )}
                          </button>
                        </div>
                      </form>
                      {/* <RewardLastModal/> */}
                    </div>
                    
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default RewardModal
