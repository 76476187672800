import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import ServiceCall from "../../../ServiceCall";
// import faker from 'faker';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { ConstructionOutlined } from "@mui/icons-material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

function Chart({chartTitle,url,name,labels,count,StartYear,loader, EndYear,filter}) {

  const [corr, setCorr] = useState([]);
  const [label, setlabel] = useState([])
  const [date, setDate] = useState([])

  useEffect(()=>{
    handleData(url)
  },[StartYear, EndYear, loader])


  const handleData = async(url)=>{
    const result = await ServiceCall.get(`${url}/${StartYear}/${EndYear}`,{
      'Authorization': `Bearer ${localStorage.getItem("rxone-admin")}`
    })

    if(result){
      setlabel(result.data[name])
      handleCollect(result.data[name])
    }
  }
  
  const handleCollect =  (items) =>{
    const values = [];
    const peak = [];
    items.map((e)=>{
      values.push(e.reg_date)
      peak.push(e[count])
    })
    
    setCorr(peak)
    setDate(values)
    filter(false)
  }


  return ( 
    <Line 
    style={{height:"100px !important", widht:"100px !important"}}
      datasetIdKey={`id_${chartTitle}`}
      data={{
        labels:date,
        datasets: [
          {
            id: 1,
            label: labels,
            data: corr,
            backgroundColor: "orange",
            borderColor: "orange",
          },
        ],
      }}
      width={100}
  height={50}
      options={{
        responsive: true,
        scales: {
          x: {
            beginAtZero:true,
            display: true,
            grid: { display: false },
          },
          y: {
            beginAtZero: true,
            max: 6,
          },
        },
        plugins: {
          title: {
            display: true,
            text: chartTitle,
            color: "#929292",
            font: {
              size: 24,
            },
          },
          legend: {
            position: "bottom",
            maxHeight: 20,
          },
        },
      }}
    />
  );
}

export default Chart;
