import React,{useState} from 'react'
import {Modal, Button} from "react-bootstrap"
import ServiceCall from '../ServiceCall'


function FacilitiesApprove(props) {

    const [loader, setLoader] = useState(false);

    const handleSubmit = async () =>{
        setLoader(true)
        try{
            const result = await ServiceCall.post(`rxone/admin/approve/facility/registration/${props.email}/${props.hospital}`,"",{
              'Authorization': `Bearer ${localStorage.getItem("rxone-admin")}`
            })
        
            if(result){
              setLoader(false)
              props.onHide(false)
              
            }
          }catch(err){
            console.log(err)
            
          }
           
    }
  return (
    <Modal 
    {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  > 
    <Modal.Header className='ms-second-bg-color p-0' >
    </Modal.Header>
    <Modal.Body>
         <div style={{width:"80%"}} className="m-auto pt-5 ms--font-roboto pb-5">
            <div className="d-flex flex-column align-items-center">
            <h6 className='fs-6 text-center'>Before approval please make sure you have carefully reviewed Facility details and Registration Certificate. </h6>
            <p>(Please note, that your details will be captured as authorizer for the facility.)</p>
            </div>
            <div className='d-flex justify-content-around mt-4'>
                <button className='btn fw-bold action_btn_black ms-focus fs-6 btn__center pe-4 ps-4 ' onClick={() =>props.onHide(false)}>Cancel</button>
                <button className='btn fw-bold action_btn  ms-focus fs-6 btn__center pe-4 ps-4 ' onClick ={handleSubmit}>{loader?
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 
                "Approve"
                }</button>
            </div>
            
        </div>
    </Modal.Body>
  </Modal>
  )
}

export default FacilitiesApprove