import React,{useEffect, useState} from 'react'
import ServiceCall from "../../ServiceCall"
import "../../css/Facilities.css"
import { unstable_ownerWindow } from '@mui/utils';
import { CatchingPokemonSharp, CompressOutlined, ConstructionOutlined, LocalConvenienceStoreOutlined } from '@mui/icons-material';
import FacilitiesDetails from './FacilitiesDetails';
import FacilitiesApprove from '../FacilitiesApprove';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DoneIcon from '@mui/icons-material/Done';
// import bufferImage from 'buffer-image';

function Facilities() {
  const[facilitiesDetails, setFacilitiesDetails] = useState("");
  const[userData, setUserData] = useState("")
  const[heading, setHeading] = useState(0)
  const[hosId, setHosId] = useState("");
  const[email, setEmail] = useState("");
  const[test, setTest] = useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const[ApproveModal, setApproveMOdal] = useState(false)


  useEffect(()=>{
    handleFacilities();
  },[])

  const handleFacilities = async() => {
       const result = await ServiceCall.get("rxone/admin/list/active/users",{
        'Authorization': `Bearer ${localStorage.getItem("rxone-admin")}`
       })
       if(result){

         setFacilitiesDetails(result.data.rxone_users)
          setUserData({message:"Please Select User From Drop Down"})
          console.log(result.data.rxone_users[0].email)
       }
  }
  const handleUserDetail = async (email) =>{
    setEmail(email)
    if(email === ""){
      setUserData({message:"Please Select User From Drop Down"})
    }else{
      const res= await ServiceCall.get(`rxone/admin/list/facilities/${email}`,{
        'Authorization': `Bearer ${localStorage.getItem("rxone-admin")}`
        })
      if(res){
        setUserData(res.data)
      }

    }
    }



  const handleModal = (item) =>{
    setHosId(item.hos_id);
    setModalShow(true);

  }

  const handleApprove =(item) =>{
    setHosId(item.hos_id);
    setApproveMOdal(true);
  }
 console.log(userData)

  return (
     <>
     {/* Facilities MOdal start from here  */}

     <FacilitiesDetails hospital={hosId} email={email} show={modalShow}  onHide={() => setModalShow(false)} /> 
     <FacilitiesApprove hospital={hosId} email={email} show={ApproveModal}  onHide={setApproveMOdal} /> 

    {/* Facilities MOdal start from here  */}

    <div className='btn__center facilites__main__container ms--font-roboto'> 
  <div className=' facilities__container shadow-sm mb-5 border rounded d-flex justify-content-center ' >
   <div className='facilities__table'>
     <div className='facilites__heading shadow-sm mb-4 row border rounded m-0'>
     <h3 className={`col-6 text-center ${heading ===0 && "facilites__heading__nav"}`} onClick={()=>{setHeading(0)}}>FACILITIES</h3> 
     <h3 className={`col-6 text-center ${heading ===1 && "facilites__heading__nav"}`} onClick={()=>{setHeading(1)}}>REPORTING</h3> 
     </div>
     <div className=' shadow-sm bg-body facilities__user__container rounded '>
     <select  onChange={(e)=>handleUserDetail(e.target.value)} className="form-select ms-focus facilities__user__list mt-2">
       <option value="" >Select User</option>
      {
        facilitiesDetails?.length === 0?
        <option value="loading">...Loading</option> :
       facilitiesDetails.map(e=>(
         <option key={e.eamil} value={e.email}>{e?.email}</option>
       ))

      }
    </select>
     </div>

     <div className='facilities__table__list mt-3 container-fluid'>
     <div className="row facilities__main__table">
       <div className="col-1 long fw-bold border btn__center  text-break">Registration No.</div>
       <div className="col fw-bold border btn__center  text-break ">Accreditaction By </div>
        <div className="col fw-bold border btn__center text-break  ">Facility Name</div>
       <div className="col-3 fw-bold border btn__center  text-break">Contact Email</div>
       <div className="col fw-bold border btn__center   text-break ">Contact Phone</div>
       <div className="col fw-bold border btn__center   text-break ">Review Details</div>
       <div className="col-1 fw-bold border btn__center  text-break  ">Registration Status </div>
       <div className="col fw-bold border btn__center  text-break ">Action</div>

     </div>
       { userData === ""  ?
       <>
       <div style={{height:"200px"}} className='btn__center'>
       <span className="spinner-border spinner-border-xl" role="status" aria-hidden="true"></span>
       </div>
       </>
        :
                                         
         <div  className="row grid__font">

        {   Object.keys(userData)[0] === "message" ?
            <div style={{backgroundColor:"var(--background-color)"}} className="col border fw-bold text-center pt-2 pb-2" key={userData.message}>{userData.message} </div>

             : userData.registered_facilities.map((item)=>(
                <>
                <div className="col-1 border btn__center  text-break  ">{item?.hosp_registration_no}</div>
                  <div className="col border btn__center  text-break ">{item?.hosp_accreditation_by} </div>
                   <div className="col border btn__center  text-break">{item?.hosp_name}</div>
                  <div className="col-3 border btn__center  text-break">{item?.email}</div>
                  <div className="col border btn__center   text-break ">{item?.phone1}</div>
                  <div className="col border btn__center  text-break  "><button style={{height:"30px"}} onClick={()=>{handleModal(item)}} className='btn ms-secondary-btn btn__center fs-6 '>View</button></div>
                  <div className="col-1 border btn__center text-break ">{item.registration_approved && item.registration_submitted? <DoneIcon className='text-success' sx={{fontSize:"30px"}}/> : <MoreHorizIcon className='text-danger' sx={{fontSize:"30px"}}/>}</div>
                  <div className="col border btn__center text-break "><button onClick={()=>handleApprove(item)}  className={`btn fw-bold action_btn  ms-focus fs-6 btn__center ${item.registration_approved && item.registration_submitted ? "disabled":""} aria-disabled="true"`}>Approve</button></div>
                </> 
             ))
        }
       </div>
        // ))
     }  
     </div>
   </div>
  </div>
  </div>
  </>
  )
}

export default Facilities