import React, { useEffect,useState } from 'react'
import Switch from "@mui/material/Switch";
import ServiceCall from '../../../ServiceCall';
import Snackbar from '../../../Theme/Snackbar';

const ClaimBar = ({data,refresh}) => {
  const[errorMsg, setErrorMsg] = useState("")

  useEffect(()=>{
    if(data === "" || data === "refresh"){
      setViewData([])
      handleUserData("all")
    }else{
      handleUserData(data)
    }
  
  },[data])

  const [viewData, setViewData] = useState([])

  const handleUserData = async (email) =>{
    try {
      const result = await ServiceCall.get(`rxone/admin/list/reward/redemptions/live/${email}`, {
        'Authorization': `Bearer ${localStorage.getItem("rxone-admin")}`
      })
      if (result) {
        setViewData(result.data.records)
      }

    } catch (err) {
      setErrorMsg(err.response.data.message || "")
    }
  }
  const fetchDate = (date) => {
    const dateFetch = date.split(" ").slice(0, 1);
    return dateFetch;
  };

  const handleApproved = async(redemptionID)=>{
    try{
      const  result = await ServiceCall.post(`rxone/admin/approve/reward/redemptions/live/${redemptionID}`,"",{
         'Authorization': `Bearer ${localStorage.getItem("rxone-admin")}`
       })

       if(result){
        setErrorMsg(result.data.message)

       }

    }catch(err){
      setErrorMsg(err.response.data.message || "")
    }
    
  }

  const handleConfirm = async(redemptionID)=>{
    try{
      const  result = await ServiceCall.post(`rxone/admin/approve/reward/redemptions/live/${redemptionID}`,"",{
         'Authorization': `Bearer ${localStorage.getItem("rxone-admin")}`
       })

       
       if(result){
        setErrorMsg(result.data.message)
       }


    }catch(err){
      setErrorMsg(err.response.data.message || "")
    }
    
  }
  
  return (
    <>
    <Snackbar msg={errorMsg} setmsg={setErrorMsg} refresh={refresh}/>
    <div className="facilities__table__list mt-3 reward_scroll container-fluid">
      <div className="row rewardbar__nav facilities__main__table">
        <div className="col-1 long fw-bold border btn__center  text-break">
          Reward Title
        </div>
        <div className="col-1 fw-bold border btn__center  text-break ">
          Created On
        </div>
        <div className="col-2 fw-bold border btn__center  text-break ">
          Claimed By(Email)
        </div>
        <div className="col-1 fw-bold border btn__center text-break  ">
        Claimed By(Phone)
        </div>
        <div className="col-1 fw-bold border btn__center  text-break">
        Claimed Quantity
        </div>
        <div className="col-1 fw-bold border btn__center   text-break ">
        Claimed Rx Points
        </div>
        <div className="col-1 fw-bold border btn__center   text-break ">
        Claim Date
        </div>
        <div className="col-1 fw-bold border btn__center  text-break  ">
         Reward Approved?
        </div>
        <div className="col-1 fw-bold border btn__center  text-break ">Reward Fullfilled?</div>
        <div className="col-1 fw-bold border btn__center  text-break ">1.  Approve Reward</div>
        <div className="col-1 fw-bold border btn__center  text-break ">2.  Confirm Fulfilment</div>
      </div>
      {viewData.length === 0 ? (
        <>
          <div style={{ height: "200px" }} className="btn__center">
            <span
              className="spinner-border spinner-border-xl"
              role="status"
              aria-hidden="true"
            ></span>
          </div>
        </>
      ) : (
        viewData.map((item, list) => (
           <>
              <div className="row grid__font" key={item.redemption_id}>
                <div className="col-1 border btn__center text-break">
                  {item?.title || ""}
                </div>
                <div className="col-1 border btn__center text-break ">
                  {fetchDate(item?.date || "")}
                </div>
                <div className="col-2 border btn__center text-break">
                  {item?.email || ""}
                </div>
                <div className="col-1 border btn__center text-break">
                  {item?.phone || ""}
                </div>
                <div className="col-1 border btn__center text-break">
                  {item?.quantity || ""}
                </div>
                <div className="col-1 border btn__center text-break">
                  {item?.redeemed_points || ""}
                </div>
                <div className="col-1 border btn__center text-break">
                {fetchDate(item?.claimed_on || "")}
                </div>
                 <div className="col-1 border btn__center text-break">
                {item?.is_approved? "true":"false"}
                </div>
                 <div className="col-1 border btn__center text-break">
                {item?.is_fulfilled? "true" :"false" }
                </div>
                <div className="col-1 border btn__center text-break">
                  <button className="btn ms-secondary-btn fs-6 w-100 p-0" onClick={()=>handleApproved(item?.redemption_id)}>Approve</button>
                </div>
                <div className="col-1 border btn__center text-break text-danger">
                <button onClick={()=>handleConfirm(item?.redemption_id)} className="btn ms-secondary-btn fs-6 w-100 p-0 ">Confirm 
                </button>
                </div>
              </div>
            </>
        ))
      )}
    </div>
  </>
  )
}

export default ClaimBar