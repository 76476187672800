import React,{useEffect} from 'react'
import {Outlet} from "react-router-dom"
import Header from './Header'
import HeaderTop from './HeaderTop'
import UserAuth from '../context/UserAuth'

function Layout() {
const [user, setUser] = UserAuth();

useEffect(() => {
    const timer = setTimeout(()=>{
      setUser("")
    },600000)
    return () => clearTimeout(timer)
}, [])

  return (
    <div className='app '>
      <div className='row'>
      <div  className='col-2 m-0 p-0 main_header_width'>
      <Header/>
      </div>
      <div  className='col m-0 p-0 second_header_width'>
      <HeaderTop/>
      <Outlet/>
      </div>
      </div>

    </div>
  )
}

export default Layout