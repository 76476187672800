import React,{useState} from "react";
import Switch from '@mui/material/Switch';
import ServiceCall from "../../../ServiceCall";
import ApiAlert from "../../ApiAlert";
import Snackbar from "../../../Theme/Snackbar";

const RewardBar = ({ data,viewdata,refresh,rewardsid }) => {
  const [errorMsg, setErrorMsg] = useState("")
  const fetchDate = (date) => {
    const dateFetch = date.split(" ").slice(0, 1);
    return dateFetch;
  };

  const [checked, setChecked] = React.useState(false);
  const [loader, setLoader]= useState(false)

  const handleDelete = async (reward) =>{
    try{
      const  result = await ServiceCall.delete(`rxone/admin/delete/user/reward/${reward}`,{
         'Authorization': `Bearer ${localStorage.getItem("rxone-admin")}`
       })
       if(result){
        setErrorMsg(result.data.message ||"")
       }

    }catch(err){
       setErrorMsg(err.response.data.message || "")
    }
  }

  const handleChange = async (e, reward) =>{
      setChecked(e.target.checked)

      if(e.target.checked){
        try{
          const  result = await ServiceCall.put(`rxone/admin/user/reward/publish/switch/${reward}`,"",{
             'Authorization': `Bearer ${localStorage.getItem("rxone-admin")}`
           })
    
        }catch(err){
           console.log(err)
        }
      }
  }

  return (
    <>
    <Snackbar msg={errorMsg} setmsg={setErrorMsg} refresh={refresh}/>
      <div className="facilities__table__list reward_scroll  mt-3 container-fluid">
        <div className="row rewardbar__nav facilities__main__table">
          <div className="col-2 long fw-bold border btn__center  text-break">
            Reward Title
          </div>
          <div className="col-1 fw-bold border btn__center  text-break ">
            Created On
          </div>
          <div className="col-1 fw-bold border btn__center text-break  ">
            Published On
          </div>
          <div className="col-1 fw-bold border btn__center  text-break">
            Required Rx Points (To Claim)
          </div>
          <div className="col-1 fw-bold border btn__center   text-break ">
            Available Quantity
          </div>
          <div className="col-1 fw-bold border btn__center   text-break ">
            Valid Till
          </div>
          <div className="col-1 fw-bold border btn__center  text-break  ">
            Publish/Unpublish
          </div>
          <div className="col-2 fw-bold border btn__center  text-break "></div>
          <div className="col-2 fw-bold border btn__center  text-break "></div>
        </div>
        <div className="">
        {data === "" ? (
          <>
            <div style={{ height: "200px" }} className="btn__center">
              <span
                className="spinner-border spinner-border-xl"
                role="status"
                aria-hidden="true"
              ></span>
            </div>
          </>
        ) : (
          data.map((item, list) => (
              <div className="row grid__font" key={item.reward_id}>
                <div className="col-2 border btn__center text-break">
                  {item.title || ""}
                </div>
                <div className="col-1 border btn__center text-break ">
                  {fetchDate(item.created_on || "")}
                </div>
                <div className="col-1 border btn__center text-break">
                  {fetchDate(item.published_on || "")}
                </div>
                <div className="col-1 border btn__center text-break">
                  {item.required_claim_points || ""}
                </div>
                <div className="col-1 border btn__center text-break">
                  {item.allowed_quantity || ""}
                </div>
                <div className="col-1 border btn__center text-break">
                  {item.valid_till || ""}
                </div>
                
                <div className="col-1 border btn__center text-break">
                  <Switch
                  //  checked={checked}
                   onChange={(e)=>handleChange(e, item?.reward_id)}
                    color="warning"
                  />
                </div>
                <div className="col-2 border btn__center text-break">
                  <button className="btn ms-secondary-btn fs-6  p-1" onClick={()=>viewdata(item?.reward_id)}>View/Edit</button>
                </div>
                <div className="col-2 border btn__center text-break text-danger">
                <button onClick={()=>handleDelete(item?.reward_id)} className="btn ms-secondary-btn fs-6 p-1 ">Remove 
                </button>
                </div>
              </div>
          ))
          )}
          </div>
      </div>
    </>
  );
};

export default RewardBar;
