import React from 'react'
import { Formik } from 'formik';
import { useFormik } from 'formik';
import "../../css/onboard.css"

function OnBoard() {


  const validate = (values, props /* only available when using withFormik */) => {
    const errors = {};
  
    if (!values.email) {
      errors.email = '*Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = '*Invalid email address';
    }

    if(!values.phone){
      errors.phone = "*Required"
    }

    if(!values.pasasword){
      errors.password = "*Required"
    }else if( ! /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(values.password)){
      errors.email = "*Minimum eight characters, at least one letter and one number:"
    }

    return errors
  }

    const formik = useFormik({
      initialValues: {
          email:"",
          phone:"",
          firstname:"",
          lastname:"",
          password:"",
          is_staff:"",
          is_admin:"",
          category:"",
          department:""
      },
      validate,


      onSubmit: values => {
        alert(JSON.stringify(values, null, 2));
      },
    });


  return (
    <div className='btn__center ms--font-roboto facilites__main__container ms--font-roboto'> 
    <div className=' facilities__container shadow-sm mb-5 border rounded d-flex justify-content-center ' >
     <div className='facilities__table btn__center'>
     <form className='onboard__form' onSubmit={formik.handleSubmit}>
       <div  className='d-flex flex-column'>
        <label className='' htmlFor="firstName">Email {formik.errors.email? <span className='text-danger ms-3 error'>{formik.errors.email}</span> : null}</label>
        <input
          id="email"
          name="email"
          type="email"
          className='form-control ms-focus'
          onChange={formik.handleChange}
          value={formik.values.email}
        />
       </div>
       <div className='d-flex flex-column'>
        <label className='' htmlFor="firstName">Phone {formik.errors.phone? <span className='text-danger ms-3 error'>{formik.errors.phone}</span> : null}</label>
        <input
          id="phone"
          name="phone"
          type="text"
          className='form-control ms-focus'
          onChange={formik.handleChange}
          value={formik.values.phone}
        />
       </div>
       <div className='d-flex '>
       <div className='d-flex flex-column me-2'>
        <label className='' htmlFor="firstName">First Name {formik.errors.firstname? <span className='text-danger ms-3 error'>{formik.errors.firstname}</span> : null}</label>
        <input
          id="firstName"
          name="firstname"
          type="tel"
          className='form-control ms-focus'
          onChange={formik.handleChange}
          value={formik.values.firstname}
        />
       </div>
       <div className='d-flex flex-column'>
        <label className='' htmlFor="firstName">Last Name {formik.errors.lastname? <span className='text-danger ms-3 error'>{formik.errors.lastname}</span> : null}</label>
        <input
          id="firstName"
          name="lastname"
          type="tel"
          className='form-control ms-focus'
          onChange={formik.handleChange}
          value={formik.values.lastname}
        />
       </div>
        </div>
        <div className='d-flex flex-column'>
        <label className='' htmlFor="firstName">Password {formik.errors.password? <span className='text-danger ms-3 error'>{formik.errors.password}</span> : null}</label>
        <input
          id="password"
          name="password"
          type="text"
          className='form-control ms-focus'
          onChange={formik.handleChange}
          value={formik.values.pasasword}
        />
       </div>
       <div className='d-flex flex-column'>
        <label className='' htmlFor="firstName">Category {formik.errors.category? <span className='text-danger ms-3 error'>{formik.errors.category}</span> : null}</label>
        <input
          id="category"
          name="category"
          type="text"
          className='form-control ms-focus'
          onChange={formik.handleChange}
          value={formik.values.category}
        />
       </div>
      
      <div className='d-flex flex-column'>
        <label className='' htmlFor="firstName">Department {formik.errors.email? <span className='text-danger ms-3 error'>{formik.errors.email}</span> : null}</label>
        <input
          id="Department"
          name="department"
          type="text"
          className='form-control ms-focus'
          onChange={formik.handleChange}
          value={formik.values.department}
        />
      </div>
      <div className='d-flex justify-content-around'>
           <div className=''>
              <input
                id="isStaff"
                name="is_staff"
                type="checkbox"  
                onChange={formik.handleChange}
                value={formik.values.is_staff}
              />
              <label className='ms-2' htmlFor="firstName">Is Staff</label>
           </div>
           <div>
              <input
                id="isAdmin"
                name="is_admin"
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.is_admin}
              />
              <label className='ms-2' htmlFor="firstName">Is Admin</label>
           </div>
       </div>

       <button className='btn w-100 ms--font-roboto fs-5 ms-secondary-btn p-0 pt-1 pb-1 ' type="submit">Submit</button>
     </form>
      </div>
      </div>
       </div>
  )
}

export default OnBoard