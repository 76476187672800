import React,{useState} from 'react'
import ChartFlow from './home/ChartFlow';
import axios from "axios";
import { useEffect } from "react";
import Chart from "./home/Chart";
import "../../css/home.css"
import Dashboard from "./home/Dashboard";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import { ContactlessOutlined } from '@mui/icons-material';
// import Api from "../utils/api/Api";
// import ApiEndPoints from "../utils/api/ApiEndPoints";

function Home() {
  const { mode, setMode } = "test";
  // const [type, setType] = useState("text")
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [SecondStartDate, setSecondStartDate] = useState();
  const [SecondEndDate, setSecondEndDate] = useState();
  const [loader, setLoader] = useState(false);
  const [secondloader, setSecondLoader] = useState(false);
  // ************ count online and inperson state ************* 
  const [TotalOnline, setOnline] = useState(0);
  const [TotalInPerson, setInPerson] = useState(0);
// ************ count Total cash ************************** 
const [TotalOnlineCash, setOnlineCash] = useState(0);
const [TotalInPersonCash, setInPersonCash] = useState(0);
  const today = new Date();
  const Time = today.toLocaleTimeString();
  let perfectTime = Time.split(":").slice(0,2).join(":")
  let timeZone = Time.split(" ").slice(1,3).join(" ")
  var currentYear = today.getFullYear()+'-01-01'
  var TodayDate = today.getFullYear()+'-0'+(today.getMonth()+1)+'-'+today.getDate();
  var YesterdayDate = today.getFullYear()+'-0'+(today.getMonth()+1)+'-'+(today.getDate()-1);

  // console.log("today",today.getDate())

  useState(()=>{
     setStartDate(currentYear);
     setEndDate(TodayDate)
     setSecondStartDate(currentYear)
     setSecondEndDate(TodayDate)
  },[])

 const handleSetDate = (e) =>{
    e.preventDefault()
    setLoader(true)

  }

  // console.log("SecondEndDate",SecondEndDate);

  const handleSecondSetDate = (e) =>{
    e.preventDefault()
    setSecondLoader(true)
    
  }

  return (   
   
      <div style={{width:"85vw"}} className=" w-100 mt-0 pt-0 home__container  ms--font-roboto">

<div className='pt-5 pb-5'>
{/* ******* HOME BOX**************  */}
<div className='d-flex justify-content-evenly home__box mb-5'>
  <div className='shadow-sm bg-body rounded p-2 home__box__container'>
   <h3>Appointments Scheduled Today:</h3>
   <div className='d-flex justify-content-evenly home__box__content mt-3'><p>{TotalOnline}<span>(online)</span></p> <p>{TotalInPerson}<span>(In-person)</span></p></div>
   <p><InfoOutlinedIcon className='me-1 mb-1' sx={{fontSize:20}}/>Last Update on {perfectTime} {timeZone}</p>
  </div>
  <div className='shadow-sm bg-body rounded p-2 home__box__container'>
   <h3>Total Collection Today:</h3>
   <div className='d-flex justify-content-evenly home__box__content mt-3'><p><CurrencyRupeeOutlinedIcon sx={{fontSize:30}}/>{TotalOnlineCash}<span>(online)</span></p> <p><CurrencyRupeeOutlinedIcon sx={{fontSize:30}}/>{TotalInPersonCash}<span>(cash)</span></p></div>
   <p><InfoOutlinedIcon className='me-1 mb-1' sx={{fontSize:20}}/>Last Update on {perfectTime} {timeZone}</p>
  </div>
</div>


{/* ******* SECOND CHART START FROM HERE *************  */}
<div>
<form className='d-flex mb-3  home__form  mt-0 pt-2 pb-2  ' onSubmit={handleSecondSetDate}>
<div className="home__date__container btn__center flex-column">
  <label className="form-label home__date fw-bold text-center w-100">START DATE</label>
  <input type = "date" value={SecondStartDate}  className=" home__input ms-focus" onChange={e=>{setSecondStartDate(e.target.value)}}  id="startDate"/>  
</div>
<div className=" btn__center flex-column">
  <label  className="form-label home__date fw-bold text-center w-100">END DATE</label>
  <input type="date" value={SecondEndDate} className="home__input ms-focus" onChange={(e)=>setSecondEndDate(e.target.value)} id="endDate"/>
</div>
<div className="ms-5 d-flex justify-content-center align-items-end mb-1">
<button className="btn fs-6  home__date__btn ms-focus ms-secondary-btn ">
  {secondloader? 
  <span  className="spinner-border  spinner-border-sm" role="status" aria-hidden="true"></span>   :
<span>FILTER</span>
}
  </button>
</div> 
</form>

</div>
<div  className="charts chart__container shadow-sm p-3 mb-5 bg-body rounded  d-flex justify-content-between flex-wrap  pb-4 ">
  <div  className='p-3 chart__component'>
<ChartFlow chartTitle="Appointments" url="rxone/rxopd/appointments" loader={secondloader} name="rxopd_appointments" labelF="online" type="appointment_type" labels="In Person" setonline={setOnline} setinperson={setInPerson} StartYear={SecondStartDate} EndYear={SecondEndDate} yesterday={YesterdayDate} filter={setSecondLoader}/>
  </div>
  <div className='p-3 chart__component'>
<ChartFlow chartTitle="Collection (INR)" url="rxone/rxopd/collections" loader={secondloader} name="rxopd_collections" labelF="online" type="payment_mode" labels="In Person" setonline={setOnlineCash} setinperson={setInPersonCash} StartYear={SecondStartDate} EndYear={SecondEndDate} yesterday={YesterdayDate} filter={setSecondLoader}/>
  </div>
</div>
</div>

        {/* *************** First chart Starts from here ***************  */}
        
      <form className='d-flex mb-3  home__form  mt-0 pt-2 pb-2  ' onSubmit={handleSetDate}>
          <div className="home__date__container btn__center flex-column">
            <label className="form-label home__date fw-bold text-center w-100">START DATE</label>
            <input type = "date" value={startDate}  className=" home__input ms-focus" onChange={e=>{setStartDate(e.target.value)}}  id="startDate"/>  
          </div>
          <div className=" btn__center flex-column">
            <label  className="form-label home__date fw-bold text-center w-100">END DATE</label>
            <input type="date" value={endDate} className="home__input ms-focus" onChange={(e)=>{setEndDate(e.target.value)}} id="endDate"/>
          </div>
          <div className="ms-5 d-flex justify-content-center align-items-end mb-1">
          <button className="btn fs-6  home__date__btn ms-focus ms-secondary-btn ">
            {loader? 
            <span  className="spinner-border  spinner-border-sm" role="status" aria-hidden="true"></span>   :
          <span>FILTER</span>
          }
            </button>
          </div>
        </form>
  
        <div  className="charts chart__container shadow-sm p-3 mb-5 bg-body rounded  d-flex justify-content-between flex-wrap  pb-4 ">
            <div  className='p-3 chart__component'>
          <Chart chartTitle="Sign-ups (Active Providers)" url="rxone/user/signups" loader={loader} name="rxone_signups" labels="Users Count" count ="signup_count" StartYear={startDate} EndYear={endDate} filter={setLoader}/>
            </div>
            <div className='p-3 chart__component'>
          <Chart chartTitle="Doctor Onboarded" url="rxone/reg/doctors" loader={loader} name="rxopd_doctors" labels="Doctors Count" count ="reg_doctors_count" StartYear={startDate} EndYear={endDate} filter={setLoader}/>
            </div>
            <div className='p-3 chart__component'>
          <Chart chartTitle="Facilities Registration" url="rxone/reg/facilities" loader={loader} name="rxopd_facilities" labels ="Facilites Count" count="facility_count" StartYear={startDate} EndYear={endDate} filter={setLoader}/>
            </div>
            <div className='p-3 chart__component'>
          <Chart chartTitle="Patient Onboarded" url="rxone/reg/patients" loader={loader} name="rxone_patients" labels = "Patients Count" count="patients_count" StartYear={startDate} EndYear={endDate} filter={setLoader}/>
            </div>
        </div>
      </div>
    );
}

export default Home