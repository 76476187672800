import React, { useContext } from 'react'
import {Link} from "react-router-dom"
import "../css/header.css"
import tower from "../images/Tower.svg"
import GlobelContext from '../context/GlobelContext';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CellTowerIcon from '@mui/icons-material/CellTower';

function HeaderTop() {
  const [user,setUser] = useContext(GlobelContext)
  return (
    <div className='header_top shadow-sm p-3 position-sticky bg-body msp--font-roboto'>
      <div className='d-flex justify-content-between'>
      <div style={{width:"30px"}} className='d-flex'>
      <CellTowerIcon className='ms-4' sx={{fontSize:"30px", color:"#005c74", cursor:"pointer"}}/>
      <p className='ms--font-roboto fw-bolder mb-0 mt-1 ms-2 fs-5'>ATC</p>
      </div>
      <div className='d-flex'>
        <NotificationsIcon className='me-4 header_top_button' sx={{fontSize:"30px", color:"#005c74", cursor:"pointer"}}/>
        <div className="dropdown text-end">
          <Link to="#" className="d-block link-dark text-decoration-none"  data-bs-toggle="dropdown" aria-expanded="false">
        <AccountCircleIcon className='me-4 header_top_button' sx={{fontSize:"30px", color:"#005c74", cursor:"pointer"}}/>
           </Link>
          <ul className="dropdown-menu text-small" aria-labelledby="dropdownUser1">
            <li><Link className="dropdown-item text_wrap" to="/">{user}</Link></li>
            <li><hr className="dropdown-divider"/></li>
            <li style={{cursor:"pointer"}} className=" dropdown-item" onClick ={()=>{setUser("")}}>Sign out</li>
          </ul>
        </div>
      </div>
      </div>
    </div>
  )
}

export default HeaderTop