import React,{ useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute' ,
  top: '50%',
  left: '50%',
  display:"flex",
  alignItems:"center",
  flexDirection:"column",
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function Snackbar({msg, setmsg, refresh}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

  useEffect(()=>{
    if(msg !== ""){
        handleOpen();
     }
  },[msg])

  const handleDelete=()=>{
    if(refresh && refresh !==""){
      refresh()
    }
    setmsg("")
    handleClose()
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 , fontWeight:"bold", textAlign:"center"}}>
           {msg}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <Button variant="contained" onClick={handleDelete}>Close</Button>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
