import axios from "axios"
import React, { Component } from 'react';


const baseURL = process.env.REACT_APP_ADMIN ;

class ServiceCall extends Component {
    static get(url, headers) {
        return axios.get(`${baseURL}${url}`, { headers });
    }

    static delete(url, headers) {
        return axios.delete(`${baseURL}${url}`, { headers });
      }

    static put(url,option, headers) {
        return axios.put(`${baseURL}${url}`,option, { headers });
    }

    static post(url, option, headers) {
        return axios.post(`${baseURL}${url}`, option, { headers });
    }


    static images(url, headers) {
        return (`${baseURL}${url}`,{headers})
    }

}

export const apiCall = (options) => {
    const modifiedUrl = { ...options, url: `${baseURL}${options.url}` };
    return axios(modifiedUrl);
  };

export default ServiceCall;