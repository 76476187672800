import React,{useContext, useState} from 'react'
import "../css/login.css"
import ServiceCall from '../ServiceCall';
import ReCAPTCHA from "react-google-recaptcha";
import {Modal, Button} from "react-bootstrap"
import { useNavigate, useLocation, Link } from 'react-router-dom';
import UserAuth from '../context/UserAuth';
// import backGroundIamge from "../images/login.jpg"
function Login() {
  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [emptyError, setEmptyError] = useState(false)
  const [wrongUser, setWrongUser] = useState(false)
  const [show, setShow] = useState(false);
  const [form, setForm] = useState("");
  const [captcha, setCaptcha] = useState(false)



  const [user, setUser] = UserAuth()
  const navigation  = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/" 

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  
  const handleToken = (token) => {
    setForm((currentForm) => {
     return {...currentForm, token }
    })
  }


  const handleSubmit = async (e) =>{
    e.preventDefault();
    setLoading(true)
    setCaptcha(false)
    setWrongUser(false)
    setEmptyError(false)

    if(email=="" || password == ""){
       setLoading(false);
       document.getElementById('floatingInput').style.border = "1px solid red";
       document.getElementById('floatingPassword').style.border = "1px solid red";
       setEmptyError(true)
    }

    else{
      try{

        const result = await ServiceCall.post('rxone/login/staff',{
          "email":email,
          "password":password
        })
    
        if(result){
          console.log(result)
          setLoading(false)
          document.getElementById('floatingInput').style.border = "1px solid blue";
          document.getElementById('floatingPassword').style.border = "1px solid blue";
          localStorage.setItem("rxone-admin", result.data.access_token)
          setUser(email)
          navigation(from,{replace:true});
        }
      }catch(err){
       setWrongUser(true)
       document.getElementById('floatingInput').style.border = "1px solid red";
       document.getElementById('floatingPassword').style.border = "1px solid red";
       setLoading(false)
      }
    }

  }

  return (

    <>
      <div className='login__container'> 
        <div className='login_shadow d-flex justify-content-center align-items-center'>
        {show ?  
              <Modal className="login__modal" show={show} centered={true} onHide={handleClose} animation={true}>
              <Modal.Header closeVariant="white" closeButton>
                <Modal.Title style={{color:"white"}}>Modal heading</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{color:"white"}}>Woohoo, you're reading this text in a modal!</Modal.Body>
            </Modal> 
          
          :
      
     <form className='login__form' onSubmit={handleSubmit}>
   
    <h2 className='login__heading text-center fw-bold '>Login to Control Center</h2>
    {emptyError && <p className='text-center fw-bold login__error' >Email and Password should not be Empty</p>}
    {wrongUser && <p className='text-center fw-bold login__error' >Wrong Email and Password</p>}
    {captcha && <p className='text-center fw-bold login__error' >Please fill the Captcha</p>}
    <div className="form-floating mt-4">
      <input  style={{backgroundColor:"transparent"}} autoComplete="false" type="email" className=" text-white bg-transparents form-control" id="floatingInput" placeholder="name@example.com" onChange={(e)=>setEmail(e.target.value)} />
      <label className='text-white'>Email address</label>
    </div>
    <div className="form-floating mt-2">
      <input style={{backgroundColor:"transparent"}} type="password" className="text-white bg-transparents form-control" id="floatingPassword" placeholder="Password" onChange={(e)=> setPassword(e.target.value)}/>
      <label className='text-white'>Password</label>
    </div>
    <div className=''>
    <p className='text-start mt-1 login__forget ' onClick={handleShow}>Forget password</p>
    <ReCAPTCHA
    theme="dark"
    sitekey= {process.env.REACT_APP_SITE_KEY}
    size="normal"
    onChange={handleToken}
  />
    </div>
    <button className="w-100 btn ms-primary-btn mt-2 " type="submit">Sign in {loading && 
      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}</button>
  </form>
  
}
  </div>
  </div>
</>


  )
}

export default Login