import React from 'react'
import {Modal, Button} from "react-bootstrap"

function KycSubscription(props,) {

  const { subscriptiondata, ...rest } = props

  return (


    <Modal
    {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header className='p-2 pe-2' closeButton>
      <h4 className=' ms-primary-color m-0 fw-bolder ms-3 fs-5 kycsubscription__heading ' >USER SUBSCRIPTION HISTORY</h4>
    </Modal.Header>
    <Modal.Body>
  <div className='kyc__table__list mb-5 '>
    <div className="row kyc__main__table   ms-font-lato">
         <div className="col-2  fs-6 fw-bold border text-center">Service Name</div>
         <div className="col-2 fs-6 fw-bold border text-center">Plan Name</div>
         <div className="col-2 fs-6 fw-bold border text-center">Subscribed on</div>
         <div className="col-2 fs-6 fw-bold border text-center">is Active?</div>
         <div className="col-2 fs-6 fw-bold border text-center">Activated On</div>
         <div className="col-2 fs-6 fw-bold border text-center">De-activated on</div>
       </div>
       {props.subscriptiondata.length === 0?
       <div className="d-flex justify-content-center">
       <div   className="spinner-border mt-5" role="status">
         <span className="visually-hidden">Loading...</span>
       </div>
     </div>  :
        props.subscriptiondata.user_subscriptions == null ?
        <h1 className=" fs-5 mt-3 text-center">Use has not subscribed to any plan yet !</h1> :
        props.subscriptiondata.user_subscriptions.map(items=>(
            <div className="row ms-font-lato " key={items.plan_name}>
                <div className="col-2 fs-6 p-1 border text-center">{items.service_name}</div>
                <div className="col-2 fs-6 p-1 border text-center">{items.plan_name}</div>
                <div className="col-2 fs-6 p-1 border text-center">{items.subscribed_on.split(" ").slice(0,1)}</div>
                <div className="col-2 fs-6 p-1 border text-center">{items.is_active? "True" : "False"}</div>
                <div className="col-2 fs-6 p-1 border text-center">{items.activated_on.split(" ").slice(0,1)}</div>
                <div className="col-2 fs-6 p-1 border text-center">{items.unsubscribed_on}</div>
            </div>
        ))
       }
    </div>
    </Modal.Body>
  </Modal>
  )
}

export default KycSubscription