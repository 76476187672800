
import './App.css';
import {useState} from "react"
import { BrowserRouter,Routes, Route } from 'react-router-dom';
import Login from './user/Login';
import Header from './Components/Header';
import HeaderTop from './Components/HeaderTop';
import Kyc from './Components/headerComponents/Kyc';
import Home from './Components/headerComponents/Home';
import Facilities from "./Components/headerComponents/Facilities"
import Requests from './Components/headerComponents/Requests';
import Settelement from './Components/headerComponents/Settelement';
import Incident from './Components/headerComponents/Incident';
import Layout from './Components/Layout';
import Setting from './Components/headerComponents/Setting';
import RequireAuth from './Components/RequireAuth';
import Lookups from './Components/headerComponents/home/Lookups';
import OnBoard from "./Components/headerComponents/OnBoard"
import Rewards from './Components/headerComponents/Rewards';
// import {GlobelProvider} from "./context/GlobelContext"

function App() {
  const [login, setLogin] = useState(false)
  useState(()=>{
    
  },[login])
  return (
    <div className='overflow-hidden'>
      <BrowserRouter>
         <>
        <Routes>
          <Route path="/login" element={<Login/>}/>

          <Route element={<RequireAuth/>}>
         <Route  element={<Layout/>}>
          <Route path="/"  element={<Home/>}/>
          <Route path="/home/kyc" element={<Kyc/>}/>
          <Route path="/home/facilites" element={<Facilities/>}/>
          <Route path="/home/requests" element={<Requests/>}/>
          <Route path="/home/settelement" element={<Settelement/>}/>
          <Route path="/home/incident" element={<Incident/>}/>
          <Route path="/home/setting" element={<Setting/>}/>
          <Route path="/home/lookups" element={<Lookups/>}/>
          <Route path="/home/onboard" element={<OnBoard/>}/>
          <Route path="/home/rewards" element={<Rewards/>}/>
          </Route>
          </Route>
          <Route path="*" element ={<Login/>}/>
         </Routes>
         
         </>
    </BrowserRouter>
    </div>
  );
}

export default App;
