import React, { useEffect, useState } from 'react'
import {Modal, Button} from "react-bootstrap"
import ServiceCall from '../../ServiceCall'
import fileDownload from 'js-file-download';
import bufferImage from "buffer"

function FacilitiesDetails(props) {
    const [userData, setUserData] = useState("");
    const [logo, setLogo] = useState("");
    const [certificate, setCertificate] = useState("");

    useEffect(()=>{
        if(props.show){

            handleReviewDetails()
            handleCertificate()
        }

        return()=>{
            setUserData("")
            setCertificate("")
            setLogo("")
        }

    },[props.show])

   const handleReviewDetails = async() =>{
    try{
        const result = await ServiceCall.get(`rxone/admin/registered/facility/details/${props.email}/${props.hospital}`,{
          'Authorization': `Bearer ${localStorage.getItem("rxone-admin")}`
        })
    
        if(result){
            setUserData(result.data)
        }
      }catch(err){
        console.log(err)
        
      }
    }

    const handleLogo =  () =>{
            const result =  ServiceCall.images(`rxone/admin/registered/facility/logo/${props.email}/${props.hospital}`,{ 'Authorization': `Bearer ${localStorage.getItem("rxone-admin")}`})
            return result
    }

    const handleCertificate = async () =>{
        try{
            const result = await ServiceCall.get(`rxone/admin/registered/facility/certificate/${props.email}/${props.hospital}`,{
              'Authorization': `Bearer ${localStorage.getItem("rxone-admin")}`
            })
        
            if(result){
                // const base64String = btoa(String.fromCharCode(...new Uint8Array(result.data)));)
                setCertificate(result.data)
                console.log(result)
            }
          }catch(err){
            console.log(err)
            
          }

    }

    const handleDownload = () =>{
        fileDownload(certificate, 'certificate.png');
    }

    console.log(certificate)

  return (
    <Modal 
    {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  > 
    <Modal.Header className='ms-second-bg-color pt-1 ms-focus' closeButton>
    </Modal.Header>
    <Modal.Body>
        <>
        {
            userData?.length===0 ?
            <div className='d-flex justify-content-center align-items-center'>
            <span className="spinner-border spinner-border-xl" role="status" aria-hidden="true"></span>
            </div>  : 

       <div className="row">
           <div className="col-8 ">
               <div className='row mt-2'>
                   <p className='col-6 text-center'>Hospital Name</p>
                   <input value={userData?.hosp_name} type="text" className='col-6' disabled/>
               </div>
               <div className='row mt-2'>
                   <p className='col-6 text-center'>Accreditation By </p>
                   <input value={userData?.hosp_accreditation_by} type="text" className='col-6' disabled/>
               </div>
               <div className='row mt-2'>
                   <p className='col-6 text-center'>Registration No.</p>
                   <input value={userData?.hosp_registration_no} type="text" className='col-6' disabled/>
               </div>
               <div className='row mt-2'>
                   <p className='col-6 text-center'>Beds Count</p>
                   <input value={userData?.beds_count} type="text" className='col-6' disabled/>
               </div>
               <div className='row mt-2'>
                   <p className='col-6 text-center'>Contact Email</p>
                   <input value={userData?.email} type="text" className='col-6' disabled/>
               </div>
               <div className='row mt-2'>
                   <p className='col-6 text-center'>Contact No. (1)</p>
                   <input value={userData?.phone1} type="text" className='col-6' disabled/>
               </div>
               <div className='row mt-2'>
                   <p className='col-6 text-center'>Contact No.(2) </p>
                   <input value={userData?.phone2} type="text" className='col-6' disabled/>
               </div>
               <div className='row mt-2'>
                   <p className='col-6 text-center'>Address</p>
                   <input value={userData?.address} type="text" className='col-6' disabled/>
               </div>
               <div className='row mt-2'>
                   <p className='col-6 text-center'>City</p>
                   <input value={userData?.city} type="text" className='col-6' disabled/>
               </div>
               <div className='row mt-2'>
                   <p className='col-6 text-center'>Country</p>
                   <input value={userData?.country} type="text" className='col-6' disabled/>
               </div>

           </div>
           <div className="col-4">
               <img className='img-fluid ' src={handleLogo()}  alt="" />
               <div>
                      
                     {   certificate === "" ?
                        <div className='d-flex justify-content-center align-items-center'>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </div>       :
                            <div className="facilites__certificate" onClick={handleDownload}>
                                {/* <img className="img-fluid " src={`data:image/png;base64,${certificate}`} alt=""/> */}
                                    <img  className='img-fluid' alt="" src={certificate}/>
                            </div>
                                
                   }
                   
               </div>
           </div>
       </div>
        }
        </>
    </Modal.Body>
  </Modal>
  )
}

export default FacilitiesDetails