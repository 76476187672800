import React from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TagIcon from '@mui/icons-material/Tag';
import { useEffect,useState } from 'react';
import ServiceCall from '../../../ServiceCall';

const style = {
    position: 'absolute' ,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "fit-content",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };



const RewardLastModal = ({rewardsid, setopen, open}) => {
  const handleClose = () => setopen(false);
  const [viewData, setViewData] = useState("")

  useEffect(()=>{
    if(open){
      handleViewData()
    }
  },[open])

  
  const handleViewData = async () => {
    try {
      const result = await ServiceCall.get(`rxone/admin/user/reward/details/${rewardsid}`, {
        'Authorization': `Bearer ${localStorage.getItem("rxone-admin")}`
      })
      if (result) {
        setViewData(result.data);
      }

    } catch (err) {
      console.log(err)
    }
  }


  return (
    <div>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography className="fw-bold" id="modal-modal-title" variant="h6" component="h2">
         Two Complimentary Tickets from BookMyShow
        </Typography>
        <hr />
        <div className='d-flex justify-content-between'>
            <div>
              <CalendarMonthIcon />
              <span className='ms-2 fs-6'>Valid Till : <span>{viewData?.valid_till || ""}</span></span>
            </div>
            <div>
              <TagIcon />
              <span className='ms-2 fs-6'>Allowed Quantity : <span>{viewData?.allowed_quantity || ""}</span></span>
            </div>
        </div>
        <hr />
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
         <h5>How to Redeem</h5>
         <ol>
          { viewData .length !== 0 ?
            viewData?.redemption_steps?.map((list)=>(
              <li>{list}</li>
            ))
            :
          ""
          }
         </ol>
         <h5>Terms and Condition</h5>

         <ol>
          {
            viewData .length !== 0 ?
            viewData?.terms_conditions.map((list)=>(
              <li>{list}</li>
            ))
            :
            ""
          }
         </ol>
        </Typography>
        <Typography className='btn__center'>
          <Button className="text-dark" variant="contained" disabled>
           {`Avail for ${viewData?.required_claim_points || "none"} Points`}
          </Button>
        </Typography>
      </Box>
    </Modal>
  </div>
  )
}

export default RewardLastModal