import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import ServiceCall from "../../../ServiceCall";
// import faker from 'faker';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { ConstructionOutlined, SettingsEthernetOutlined } from "@mui/icons-material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);


function ChartFlow({chartTitle,url,name,labelF,labels,StartYear,setonline,setinperson,type, EndYear,yesterday,loader,filter}) {
 
    const [OnlineCorr, setOnlineCorr] = useState([])
    const [InPersonCorr, seetInPersonCorr] = useState([]);
    const [label, setlabel] = useState([])
    const [date, setDate] = useState([])
    const [totaOnlineCollection, setOnlineCollection] = useState([])
    const [totaCashCollection, setCashCollection] = useState([])
  
    useEffect(()=>{
        handleData(url)
      return () =>{
        setonline(0)
        setinperson(0)
      }
    },[StartYear, EndYear,loader])


  
  
    const handleData = async(url)=>{
      const result = await ServiceCall.get(`${url}/${StartYear}/${EndYear}`,{
        'Authorization': `Bearer ${localStorage.getItem("rxone-admin")}`
      })
  
      if(result){
        const data = result.data[name]
        setlabel(data)
        handleCollect(data)
        handleValues(url)
        filter(false)
    }
}

const handleValues = async(url) =>{
  const result = await ServiceCall.get(`${url}/${yesterday}/${EndYear}`,{
    'Authorization': `Bearer ${localStorage.getItem("rxone-admin")}`
  })
  if(result){
    const data = result.data[name]
    if(data.length === 0){
      setinperson(0)
      setonline(0)
    }else if(data[type] === "online"){
         if(data[type] === "payment_mode"){
           setonline(data.total_collection)
           setinperson(0)
         }else{
            setonline(data.total_appointments)
            setinperson(0)
         }
    }else{
        if(data[type]=== "payment_mode"){
          setinperson(data.total_collection)
          setonline(0)
        }else{
          setinperson(data.total_appointments)
          setonline(0)
        }
    }
  } 
}

const handleCollect =  (items) =>{
    filter(false)
    const values = [];
    const onlineCollection = [];
    const cashCollection = [];
    const online = [];
      const inperson = [];
      items.map((e)=>{
          values.push(e.appointment_date)
          if(e[type] === "online"){
              // if(type === "payment_mode"){
              //     setonline(prev =>+e.total_collection+prev)
              //   }else{
              //       setonline(prev=>prev+1);
              //   }
            onlineCollection.push(e.total_collection)
            online.push(e.total_appointments)
        }else{
            // if(type === "payment_mode"){
            //     setinperson(prev => +e.total_collection+prev)
            // }else{
            //     setinperson(prev=>prev+1);
            // }
            cashCollection.push(e.total_collection)
            inperson.push(e.total_appointments)
        }
    })
    
    setOnlineCorr(online)
      seetInPersonCorr(inperson)
      setDate(values)
      setOnlineCollection(onlineCollection)
      setCashCollection(cashCollection)
    }

    const handleAxis =() =>{
       if(type === "payment_mode"){
           return 2500;
       }
       return 6;
    }

    const handleOnline =() =>{
        if(type === "payment_mode"){
            return totaOnlineCollection;
        }
        return OnlineCorr
    }

    const handleCash =() =>{
        if(type === "payment_mode"){
            return totaCashCollection;
        }
        return InPersonCorr
    }
  
  
    return ( 
      <Line 
      style={{height:"100px !important", widht:"100px !important"}}
        datasetIdKey={`id_${chartTitle}`}
        data={{
          labels:date,
          datasets: [
            {
              id: 1,
              label: labelF,
              data: handleOnline(),
              backgroundColor: "orange",
              borderColor: "orange",
            },
            {
                id: 2,
                label: labels,
                data: handleCash(),
                backgroundColor: "blue",
                borderColor: "blue",
              }
          ],
        }}
        width={100}
    height={50}
        options={{
          responsive: true,
          scales: {
            x: {
              beginAtZero:true,
              display: true,
              grid: { display: false },
            },
            y: {
              beginAtZero: true,
              max: handleAxis(),
            },
          },
          plugins: {
            title: {
              display: true,
              text: chartTitle,
              color: "#929292",
              font: {
                size: 24,
              },
            },
            legend: {
              position: "bottom",
              maxHeight: 20,
            },
          },
        }}
      />
    );
  }

export default ChartFlow