// import infoImg from "../../assets/images/icons/info.png";

function Dashboard(props) {
  const { title, onlineValue, rightLabel, rightValue } = props;

  return (
    <section className="home__dashboard b-shadow w-100">
      <h2>Total {title} Today:</h2>

      <div className="d-flex j-c-sp-even">
        <p>
          <span className="home__dashboard__value">{onlineValue}</span> (Online)
        </p>

        <p>
          <span className="home__dashboard__value">{rightValue}</span>{" "}
          {rightLabel}
        </p>
      </div>

      <section className="last-update-section d-flex a-i-center">
        <img src="" alt="Info Icon" />
        <p>Last Updated On: 10:30 AM</p>
      </section>
    </section>
  );
}

export default Dashboard;
