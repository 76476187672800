import {useLocation, Navigate, Outlet} from "react-router-dom"
import UserAuth from "../context/UserAuth";

const RequireAuth =() =>{
    const [user, setUser] = UserAuth();
    const location = useLocation();
    console.log(user)
    return(
        user === ""
        ?
        <Navigate to ="/login" state={{from : location}} replace/>  
        :
        <Outlet/>
        
    )
}

export default RequireAuth;