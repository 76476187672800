import react,{createContext, useContext,useState} from "react"

const GlobelContext = createContext();

export const GlobelProvider = ({children}) =>{
    const [user, setUser] = useState("")
    return(
    <GlobelContext.Provider value={[user,setUser]}>
        {children}
    </GlobelContext.Provider>

    )
}

export default GlobelContext;