import { ConnectingAirportsOutlined } from '@mui/icons-material';
import React, { useEffect,useState } from 'react'
import "../../../css/lookups.css"
import ServiceCall from '../../../ServiceCall'
function Lookups() {
    const[actionList, setActionList] = useState([]);
    const[actionKey, setActionKey] = useState("")
    const[userType, setUserType] = useState("")
    const[action,setAction] = useState("")
    const[actionError, setActionError] = useState(false)
    const[loader, setLoader] = useState(false)
    const[section, setSection] = useState(false)

    useEffect(()=>{
        handleData()
    },[])

  
    const handleData = async() =>{
        try{
        const result = await ServiceCall.get(`rxone/user/actions/lookup`,{
                'Authorization': `Bearer ${localStorage.getItem("rxone-admin")}`
              })

              if(result){
                  setActionList(result.data.actions_list)
              }
        }catch(err){
            console.log(err)
        }
    }

    const addLeadingZeros = (str)=>{
        return String(str).padStart(3,0);
    }

    const handleSearch = (e) =>{
        const value = e.target.value;
        setUserType(value)
        if(value === ""){
            setSection(true)
        }else{
            let Lastlist;
            actionList.map((list) =>{
              if(list.user_type === value){
                  Lastlist = list
              }
            })
          const actionKey = Lastlist.action_key
          const letter = actionKey.slice(0, actionKey.search(/\d/));
          const digit = actionKey.replace(letter, '');

          const newDigit = +digit +1;
          const finalDigit = addLeadingZeros(newDigit);
          setActionKey(letter+finalDigit)
        }
    }


const handleCheckout=  (e) =>{
    console.log("click")
    setLoader(true)
   e.preventDefault()
   setSection(false)
   setActionError(false)
   if(userType===""){
    setAction("")
    setSection(true);
   setLoader(false)
   }else if(action==="" || !action.match(/^[A-Z]*$/)){
    setActionError(true)
   setLoader(false)
   }else{
      handleSubmit()
   }

}


const handleSubmit = async() =>{
    const  result = await ServiceCall.put(`rxone/user/actions/lookup`,{
        "action_key":actionKey,
        "user_type":userType,
        "action":action
    },
    {
        'Authorization': `Bearer ${localStorage.getItem("rxone-admin")}`  
      })

      if(result){
          console.log(result)
      }
}


  return (
     <div className='ms-background-color pt-3 lookups__main__container btn__center flex-column ms--font-roboto'>

        {/* ************************ submit form start from here ***************  */}

             <div className='mt-2 mb-4 lookups__form p-3 w-100 border '>
                <form action="" onSubmit={handleCheckout} className='d-flex justify-content-evenly '>
                    <div className='d-flex'>
                        <input value={actionKey}  type="text" className={`text-center ms-focus lookups__user__list`} placeholder='Action Key' aria-disabled="true"/>
                        <select onChange={(e)=> handleSearch(e)} className={`form-select ms-focus ${section && "lookups__error"} lookups__user__list ms-2`}>
                            <option value="" >{section ? "Please Select User Type" : "Select an User Type"}</option> 
                            <option value="PATIENT" >PATIENT</option> 
                            <option value="DOCTOR" >DOCTOR</option> 
                        <option value="PROVIDER" >PROVIDER</option> 
                        </select>
                        <input type="text" value={action} className={`ms-2 text-center text-dark ms-focus lookups__user__list ${actionError&& "lookups__error"}`} onChange={e=>{setAction(e.target.value)}}  placeholder={actionError ? "Capital Letters Only" :"Action"}/>
                    </div>
                    <div>
                     <button className="btn fs-6  home__date__btn ms-focus ms-secondary-btn">
                         {loader?  
                            <span  className="spinner-border  spinner-border-sm" role="status" aria-hidden="true"></span>   :
                          <span>Submit</span>
                          }
                     </button>
                    </div>
                        

                </form>
             </div>

        {/* ************************ submit form end here ***************  */}


         <div className='lookups__container mb-5 shadow-sm border bg-body rounded'>
            <div className='lookups__table__list ms-4 mt-2  '>
                <div className="row lookups__main__table">
                    <div className="col-4 fw-bold border btn__center">Action Key</div>
                    <div className="col-4 fw-bold border btn__center">User Type</div>
                    <div className="col-4 fw-bold border btn__center">Action</div>
                </div>
                <div className='lookups__table__items'>
                {
                actionList.length=== 0 ?
                    <div style={{height:"200px"}} className='btn__center'>
                        <span className="spinner-border spinner-border-xl" role="status" aria-hidden="true"></span>
                    </div> 
                    :
                    actionList.map(items=>(
                    <div className=' row lookups__list' key={items.action_key}>
                        <div className="col-4 fw-bold border btn__center">{items.action_key}</div>
                        <div className="col-4 fw-bold border btn__center">{items.user_type}</div>
                        <div className="col-4 fw-bold border btn__center">{items.action}</div>
                    </div>
                    ))
                }
                </div>
            </div>
       </div>
      
     </div>
  )
}

export default Lookups