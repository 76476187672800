import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
  Autocomplete,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  CircularProgress,
  Input,
  Button
} from '@mui/material';
import { debounce } from 'lodash';
import SendIcon from '@mui/icons-material/Send';
export default function PrescriptionInput({
  label,
  fetchType,
  onSelect,
  placeholder,
  asyncData,
  pdataObj
}) {
  // const doctorContext = React.useContext(PatientpageContext);
  const [loader, setLoader] = useState(false);
  const [userQuery, setUserQuery] = useState('');
  const [notFound, setNotFound] = useState(false);
  const prescriptionINput = useRef(null);

  const [searchResult, setSearchResult] = useState([]);

  const updateQuery = () => {
    setLoader(true);
    // doctorContext
    //   .prescriptionFetch(fetchType, userQuery)
    //   .then((res) => {
    //     setLoader(false);
    //     setSearchResult(res);
    //   })
      // .catch((err) => {
      //   setLoader(false);
      //   console.log(err);
      //   setSearchResult([]);
      //   setNotFound(true);
      // });
  };

  const delayedQuery = useCallback(debounce(updateQuery, 500), [userQuery]);
  const onChange = (e) => {
    setUserQuery(e.target.value);
  };
  useEffect(() => {
    window.addEventListener('click', (e) => {
      if (
        prescriptionINput.current &&
        prescriptionINput.current.contains(e.target)
      ) {
        return;
      }
      setSearchResult([]);
    });
    return () => {
      window.removeEventListener('click', (e) => {
        if (prescriptionINput.current.contains(e.target)) {
          return;
        }
        setSearchResult([]);
      });
    };
  }, []);
  useEffect(() => {
    if (asyncData && userQuery.length > 0) {
      delayedQuery();
    }
    return delayedQuery.cancel;
  }, [userQuery, delayedQuery, asyncData]);

  function addItems(item, key) {
    onSelect(item, key);
    setSearchResult([]);
    setUserQuery('');
  }
  function enterCustomContent() {
    setNotFound(false);
    addItems(
      {
        definition: '',
        term: userQuery
      },
      'term'
    );
  }
  /**
   * call this method onenter and send button
   */
  function manualEntry() {
    delayedQuery.cancel();
    enterCustomContent();
  }
  function onKeyPress(e) {
    if (userQuery.length > 0 && e.key === 'Enter') {
      manualEntry();
    }
  }

  const renderAutoCompleteList = (sr) => {
    if (fetchType === 'drugs_list') {
      return sr.map((item, index) => {
        return (
          <ListItem
            key={item.medicine_name + index}
            className="autoCompleteList p-0"
            onClick={() => addItems(item, 'medicine_name')}
          >
            <ListItemButton className="py-0">
              <ListItemText
                primary={item.companay_name + '-' + item.medicine_name}
                secondary={item.pack_type}
                className="font-12"
              ></ListItemText>
            </ListItemButton>
          </ListItem>
        );
      });
    } else {
      return sr.map((item, index) => {
        return (
          <ListItem
            key={item.term + index}
            className="autoCompleteList p-0"
            onClick={() => addItems(item, 'term')}
          >
            <ListItemButton className="py-0">
              <ListItemText
                primary={item.term}
                secondary={item.definition}
                className="font-12"
              ></ListItemText>
            </ListItemButton>
          </ListItem>
        );
      });
    }
  };

  return (
    <div className="pos-relative" ref={prescriptionINput}>
      <div className="d-flex mb-4">
        <Input
          placeholder={placeholder}
          value={userQuery}
          onChange={onChange}
          className="w-100 pr-3 font-12"
          onKeyPress={onKeyPress}
        />
        {loader ? (
          <div className="autocompLeteLoader pos-absolute">
            <CircularProgress size={15} />
          </div>
        ) : (
          <div className="autocompLeteLoader pos-absolute">
            <SendIcon onClick={manualEntry} sx={{color:"#005c74"}} size={15} />
          </div>
        )}
        {/* {notFound && <div className="autocompleteSubmit pos-absolute"><Button variant="contained" size="small" onClick={enterCustomContent}>Click to Enter</Button></div>} */}
      </div>
      {searchResult.length > 0 && (
        <List
          dense
          className="autoSuggesionUl pos-absolute w-100 bg-white font-12"
        >
          {renderAutoCompleteList(searchResult)}
        </List>
      )}
    </div>
  );
}
