import React, { useState, useEffect } from 'react'
import RewardNavBar from "./Rewards/RewardBar"
import "../../css/reward.css"
import ServiceCall from '../../ServiceCall'
import LoopIcon from '@mui/icons-material/Loop';
import RewardModal from './Rewards/RewardModal';
import ClaimBar from './Rewards/ClaimBar';
import Snackbar from '../../Theme/Snackbar';
import { CommentBank, ContactSupportOutlined } from '@mui/icons-material';
const Rewards = () => {
  const [rewardData, setRewardData] = useState("")
  const [claimData, setClaimData] = useState("")
  const [userEmail, setUerEmail] = useState("")
  const [rewardModal, setRewardModal] = useState(false)
  const [rewardModalAdd, setRewardModalAdd] = useState(false)
  const [isViewData, setViewData] = useState([])
  const [rewardsId, setRewardsId] = useState("");
  const [navBar, setNavBar] = useState(0);
  const [errorMsg, setErrorMsg] = useState("")
  // const [refresh, setRefresh]  = useState(false)

  useEffect(() => {
    handleUser()
    handleClaim()
  }, [])


  const refresh = () =>{
    if(navBar === 0){
      setRewardData("")
      handleUser()
    }else{
      setUerEmail("refresh")
    }
  }


  const steps = ["Reward Details", "Upload Image & Save", "Publish"]

  const lineHeading = "Follow 3 Steps to Create and Publish Rewards"


  const handleUser = async () => {
    try {
      const result = await ServiceCall.get("rxone/admin/list/user/rewards", {
        'Authorization': `Bearer ${localStorage.getItem("rxone-admin")}`
      })
      if (result) {
        setRewardData(result.data.rewards)
      }

    } catch (err) {
      setErrorMsg(err.response.data.message || "")
    }
  }

  const handleClaim = async () => {
    try {
      const result = await ServiceCall.get("rxone/admin/list/active/users", {
        'Authorization': `Bearer ${localStorage.getItem("rxone-admin")}`
      })
      if (result) {
        setClaimData(result.data.rxone_users)
      }

    } catch (err) {
      setErrorMsg(err.response.data.message || "")
    }
  } 

  const viewData = async (reward_id) => {
    setRewardModal(true)
    setRewardsId(reward_id)
    try {
      const result = await ServiceCall.get(`rxone/admin/user/reward/details/${reward_id}`, {
        'Authorization': `Bearer ${localStorage.getItem("rxone-admin")}`
      })
      if (result) {
        setViewData(result.data);
      }

    } catch (err) {
      setErrorMsg(err.response.data.message || "")
    }
  }

  const handleEmail = (e) =>{
    setUerEmail(e.target.value)
  }

  return (
    <>
      <Snackbar msg={errorMsg} setmsg={setErrorMsg} refresh={refresh}/>
      <div className='btn__center facilites__main__container  ms--font-roboto'>
        <div className=' facilities__container shadow-sm mb-5 border rounded d-flex justify-content-center ' >
          <div className='facilities__table'>
            <div className='facilites__heading shadow-sm mb-4 row border rounded m-0'>
              <h3 className={`col-6 text-center ${navBar === 0 && "facilites__heading__nav"}`} onClick={() => { setNavBar(0) }}>Rewards</h3>
              <h3 className={`col-6 text-center ${navBar === 1 && "facilites__heading__nav"}`} onClick={() => { setNavBar(1) }}>Claims</h3>
            </div>
            <div className="  d-flex justify-content-between p-2 rounded reward__header ">
              {
                navBar=== 0 ?
                <div className='shadow-sm bg-body facilities__user__container'><button onClick={() => setRewardModalAdd(true)} className='btn ms-secondary-btn p-1 ps-2 pe-2 fs-6'>Add New</button></div>
                :
                <>
                <div className=' shadow-sm bg-body facilities__user__container rounded '>
                <select onChange={handleEmail} className="form-select ms-focus facilities__user__list mt-2">
                  <option value="" >Select User</option>
                 {
                   claimData?.length === 0?
                   <option value="loading">...Loading</option> :
                   claimData?.map(e=>(
                    <option key={e.eamil} value={e.email}>{e?.email}</option>
                  ))
           
                 }
               </select> 
                </div>
                </>
              }
              <div><LoopIcon onClick={refresh}  fontSize="large" sx={{ color: "red" }} /></div>
            </div>
            {navBar === 0 ?
            <>
            <RewardNavBar rewardsid={rewardsId} viewdata={viewData} data={rewardData} refresh={refresh}/>
            <RewardModal navBar={true} setrewardsid ={setRewardsId} rewardsid={rewardsId} data={isViewData} show={rewardModal} title="Manage Rewards" onHide={() => setRewardModal(false)} lineheading={lineHeading} steps={steps}  refresh={refresh}/>
            <RewardModal navBar={false} setrewardsid ={setRewardsId} rewardsid={rewardsId} data={null} show={rewardModalAdd} title="Manage Rewards" onHide={() => setRewardModalAdd(false)} lineheading={lineHeading} steps={steps} refresh={refresh}/>
            </>
            :
            <ClaimBar refresh={refresh} data={userEmail}/>
            }
            
          </div>
        </div>
      </div>
    </>
  )
}

export default Rewards